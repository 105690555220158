import React ,{ useState } from "react";

import { Alert, Button } from "react-bootstrap";
import { Link,NavLink } from "react-router-dom";
import AgGridTable from "../../components/Tables/agGridTable";
import PublishedFormsList from "./publishedForms";
import { useLocation, useParams } from 'react-router';
import { useDispatch ,useSelector} from "react-redux";
import { getPublishedFormsAction ,sendFormToSupplierAction ,deleteFormsFromSuppliers} from "../../redux/actions/suppliersAction";
import { CLIENT, IN_PROGRESS, NOT_STARTED,DRAFT, ADMIN } from "../../common/constants";

const Forms = (props) => {
  const { isAuthenticated , userDetails } = useSelector((state) => state.user);
  const { data,userInfo } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { isFormEnable } = props;
  const [show, setShow] = useState(false);
  const [gridRef, setGridRef] = useState(null)
  const deleteAction = async(data) => {
    await dispatch(deleteFormsFromSuppliers({ id: data.id,form_id: data.form_id , supplier_id:data.vendor_id }));
    gridRef.api.updateRowData({ remove: [data] })
  };
  const editAction = (data) => {
  };
  const AidRenderComponent = props => {
    return (
      <NavLink to={`/admin/suppliers/${props.data.vendor_id}/submitAnswers/${props.data.form_id}`}  >
        <span style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: "black",
          cursor:"pointer"
          
        }}>{props.value}</span> </NavLink>
      
    );
  };
  const deleteRow = props => {
    return (
    <>
      
      <div className="row">
        {userDetails && userDetails.role === CLIENT && props.data && [NOT_STARTED,DRAFT,IN_PROGRESS].indexOf(props.data.vendor_form_status) !==-1 ? (
          <>
            <div className="col-2" style={{paddingTop:"10px"}}>
                  <NavLink to={`/client/suppliers/${props.data.vendor_id}/edit/${props.data.form_id}`}  >
                    
                  <span style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    color: "black",
                    cursor:"pointer"
                    
                  }}><i
                  class="fa fa-pencil text-center ag-grid-row-icons"
                  aria-hidden="true"
                  onClick={() => {
                  }}
                ></i> </span> </NavLink>
            </div>
          </>
        ): null }
        <div className="col-2">
        <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
            >
            <i
              class="fa fa-trash text-center ag-grid-row-icons"
              aria-hidden="true"
              title="Delete"
              onClick={() =>{
                const removeIndex = props.node.rowIndex;
                props.delete(props.data,removeIndex)
              }
              }
            ></i>
          </span>
        </div>
      </div>
    </>
      
    );
  };
  const headerList = [
    {
      headerName: "Form Name",
      field: "form_name",
      filter:true,
      sortable:true,floatingFilter:true,
      cellRendererFramework: AidRenderComponent,
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          handleAction: editAction,
          url: "/admin/suppliers/%{vendor_id}%/submitAnswers",
          navigationId:"form_id"
        },
      ],
    },
    // { name: "Risk Score", key: "riskScore" },
    { headerName: "Status", field: "status", filter:true,floatingFilter:true,
    sortable:true},
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "deleteRow",
      cellRendererParams: {
        delete: deleteAction
      },
      hide : userDetails.role === ADMIN
      
    },
  ];
  
const sendNewFormHandle = async()=>{
  await dispatch(getPublishedFormsAction({supplier_id: params.id}))
  setShow(true);
}
const handleClose =()=>{
  setShow(false);
}
var sendFormButon;
if(userDetails.role !== ADMIN){
  sendFormButon = <Button variant="btn app-default-button btn btn-primary" type="button" onClick={sendNewFormHandle}>
  Send New Form
</Button>
}
const sendFormsAction  = async (data)=>{
  await dispatch(sendFormToSupplierAction({
    supplier_id: params.id,
    form_ids: data
  }))
}
  return (
    <div className="page-wrapper">
      <div className="spacer"></div>
      {isFormEnable ? null : (
        <>
          <div className="row">
            <div className="col-10">
              <p className="label-color">
                <span className="fa fa-file-text" style={{fontSize: "15px"}}></span> <b style={{marginLeft: "3px", fontSize: "15px"}}>Supplier Forms</b>
              </p>
            </div>
            <div className="col-2 text-right">
              {sendFormButon}
            </div>
          </div>
          {
            data.length > 0 ? (
              <AgGridTable columnDefs={headerList} rowData={data} frameworkComponents={{
                deleteRow
              }} handleAgGridRef={setGridRef}/>
            ) : (
              <Alert variant="success">No forms to view</Alert>
            )
          }
          
        </>
      )}
      {show ? (
        <>
        <PublishedFormsList  isOpen={show} handleClose={handleClose} sendFormsAction={sendFormsAction}/>
        </>
      ):null}
      <div className="spacer"></div>
    </div>
  );
};

export default Forms;
