import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createForm ,createSection, updateSection} from "../../redux/actions/formLibraryAction";
import { useSelector, useDispatch } from "react-redux";
import { placeholder } from "@babel/types";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ERROR, ToastMessage } from "../../common/ToastMessage";
const FormName = (props) => {
  const {id,formType,editableSection } = props;
  let placeholder = "";
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form);
  const { userDetails } = useSelector((state) => state.user);
  const [show, setShow] = useState(props.isOpen);
  const [sectionNames, setSectionNames] = useState([]);
  const [submitButtonName, SetSubmitButtonName] = useState(null);
  let validationSchema = Yup.object().shape({
    name: Yup.string().required(`${formType === "Create" ? "Form " : "Section"} Name is required`).nullable(),
  });
  if(formType == "Section"){
    validationSchema = Yup.object().shape({
      name: Yup.string().nullable(),
    });
  }
  if(formType == "Update Section"){
    validationSchema = Yup.object().shape({
      name: Yup.string().required(`Section Name is required`).nullable(),
    });
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    setShow(props.isOpen);
    setValue("name", (id && formType == "Create" ? formData.name : null ));
    if(formType == "Update Section"){
      setValue("name", editableSection);
    }
    clearErrors(["name"])
    // setValue({name: "Hariprasad"})
  }, [props.isOpen]);
  const handleClose = () => {
    setShow(false);
    props.closeModal(false);
  };
  // const handleShow = () => {
  //   setShow(true);
  // };
  const onSubmit = async (data) => {
    console.log(data,formData);
    let params = { ...data,role:userDetails.role };
    if (formData.id) {
      params.id = formData.id;
      // params.form_id = formData.id;
    }
    if(formType === "Create"){
      await dispatch(createForm(params));
      props.closeModal(false);
      reset();
    } else if(formType === "Update Section"){
      params.old_name = editableSection;
      console.log("Updatea",params)
      await dispatch(updateSection(params));
      props.closeModal(false);
      reset();
    } else{
      // let names = sectionNames.map(obj=> obj.label);
      let names = [data.name];
      if(data.name && names.length > 0){
        params = { names ,id :formData.id };
        await dispatch(createSection(params));
        if(submitButtonName === "Add One More"){
          // props.closeModal(true);
          SetSubmitButtonName(null);
        }else{
          props.closeModal(false);
        }
        reset();
      }else{
        ToastMessage(ERROR,`Please add atleast one section`)
      }
     
    }
    
   
  };
  const onChange = chips => {
    console.log(chips)
    if(chips.length > 0){
      let namesList = [...chips]
        let currentTag = namesList.pop().label;
        if(sectionNames.map(obj=> obj.label).includes(currentTag)){
          ToastMessage(ERROR,`${currentTag} already existed `)
        }else{
        setSectionNames(chips);
        }
    }
    
  }
  const onError = (data)=>{
    console.log(data)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size={"lg"}>
      <Form
            noValidate
            // validated={validated}
            // onSubmit={e => submitForm(e)}
            onSubmit={handleSubmit(onSubmit,onError)}
          >
        <Modal.Body>
          
            <Form.Group type="invalid" controlId="validationCustomUsername">
              <Form.Label>{formType == "Section" ? "Create Section" : ( formType === "Update Section" ? "Update Section" : "Create Form")  }</Form.Label>
              {formType == "Section" && false ? (
                <Typeahead
                  allowNew
                  id="custom-selections-example"
                  multiple
                  {...register("name")}
                  onChange={onChange}
                  newSelectionPrefix="Add a new section: "
                  options={[]}
                  placeholder="Type section name..."
                  // selected={sectionNames}
                />
              ) : (
                <Form.Control
                  type="text"
                  placeholder= {formType == "Section" ? "Enter Section Name" : ( formType=== "Update Section" ? "Udate Section" : "Enter Form Name" )}
                  aria-describedby="inputGroupPrepend"
                  {...register("name")}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  style={{ backgroundColor: "#e6e6e6" }}
                />
              )}
                <span className="invalid-feedback">{errors.name?.message}</span>
            </Form.Group>

            <div>
              {/* <Button
                variant="btn app-default-button btn btn-primary"
                type="submit"
              >

                {id && formType ==="Create" ? "Update" : "Create" }
              </Button> */}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
          {formType === "Section" ? (<div className={formType === "Section" ? "col-2" : ""} ></div>) : null }
          <div className={formType === "Section" ? "col-3" : "col-6"} >
            <Button
                variant="btn app-default-button btn btn-primary"
                type="submit"
              >

                {id && formType ==="Create" ? "Update" : ( formType === "Update Section" ? "Update" : "Create") }
              </Button>
          </div>
          {formType === "Section" ? (<>
           <div className={formType === "Section" ? "col-5 add-one-more-section" : "col-5"}>
            {formType === "Section" ? (
              <Button
                variant="btn app-default-button btn btn-primary"
                type="submit" onClick={()=>{
                  SetSubmitButtonName("Add One More")
                }}
              >

                Add One More
              </Button>
            ):null} 
          </div></> )
          : null }
              
          <div className={formType === "Section" ? "col-2" : "col-5"}>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
          </div>
          
          </div>
          
                        
        </Modal.Footer>
        </Form>

      </Modal>
    </>
  );
};

export default FormName;
