import React from "react";
import { Switch, Route ,Redirect  } from "react-router-dom";
import Suppliers from "../pages/Suppliers";
import FormLibrary from "../pages/FormLibrary";
import FormBuilder from "../pages/FormBuilder";
import QuestionLibrary from "../pages/QuestionLibrary";
import PendingForms from "../pages/PendingForms";
import { ROOT,CLIENT_SUPPLIERS,CLIENT_PENDING_FORMS,CLIENT_QUESTION_LIBRARY,CLIENT_FORM_LIBRARY,CLIENT_REPORTS,DASHBOARD,CLIENT_INVITE_SUPPLIER, CLIENT_FORM_BUILDER, CLIENT_COMPLAINT_PERCENTAGE, CLIENT_FORM_DUE_DATE,
  CLIENT_AUDIT_STATUS, CLIENT_RISK_SOCRE ,CLIENT_FORMS, CLIENT_AUDIT_STATUS_BY_OWNER, PROFILE,CLIENT_FORM_LIBRARY_DETAILS ,ADMIN_SUPPLIERS_ANSWERS_RESPONSE, SUBMIT_CLIENT_FORM_ANSWERS, CLIENT_SUBMIT_FORM_ANSWERS, CLIENT_COMPLETED_FORMS, CLIENT_REJECTED_FORMS,ADMIN_FORM_BUILDER, CLIENT_SUPPLIERS_EDIT_FORM ,CLIENT_SUPPLIER_ADD_FROM_LIBRARY,ADMIN_ADD_FROM_LIBRARY, CLIENT_EDIT_PUBLISHED_FORM_LIBRARY_DETAILS, CLIENT_ADD_FROM_LIBRARY_TO_PUBLISHED_FORM, CLIENT_SUBMITTED_FORMS, CLIENT_OPENEND_FORMS } from "./constants";
import Dashboard from "../components/Dashboard";
import BasicData from "../pages/Suppliers/BasicData";
import InviteSupplier from "../pages/Suppliers/InviteSupplier";
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import ComplaintsPercentage from "../pages/Reports/complaintPercentage";
import FormDueDate from "../pages/Reports/formDueDate";
import RiskScore from "../pages/Reports/riskScore";
import ReportForms from "../pages/Reports/reportForms";
import AuditStatus from "../pages/Reports/auditStatus";
import AuditStatusOwner from "../pages/Reports/auditStatusOwner";
import Profile from "../pages/Profile";
import AdminFormResponse from "../pages/Suppliers/adminFormResponse";
import SubmitAnswers from "../pages/Suppliers/submitAnswers";
import CompletedForms from "../pages/CompletedForms";
import RejectedForms from "../pages/RejectedForms";
import EditForm from "../pages/Suppliers/EditForm";
import EditPublishedForms from "../pages/FormBuilder/editPublishedForms";
import SubmittedForms from "../pages/RejectedForms/submittedForms";
import OpenedForms from "../pages/RejectedForms/OpenedForms";


export const ClientRouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} >
             <Redirect to={DASHBOARD} />
        </Route>
        <Route exact path={DASHBOARD} component={Dashboard} />
        <Route exact path={PROFILE} component={Profile} />
        {/* Client Routes Start */}
        <Route exact path={CLIENT_SUPPLIERS} component={Suppliers} />
        <Route exact path="/client/suppliers/:id" component={BasicData} />
        <Route exact path="/client/suppliers/searchSuppliers/list" component={Suppliers} />
        <Route exact path="/client/suppliers/searchSuppliers/list/:id" component={BasicData} />
        <Route exact path={CLIENT_REPORTS} component={ComplaintsPercentage} />
        <Route exact path={CLIENT_PENDING_FORMS} component={PendingForms} />
        <Route exact path={CLIENT_QUESTION_LIBRARY} component={QuestionLibrary} />
        <Route exact path={CLIENT_FORM_LIBRARY} component={FormLibrary} />
        <Route exact path={CLIENT_FORM_BUILDER} component={FormBuilder} />
        <Route exact path={CLIENT_INVITE_SUPPLIER} component={InviteSupplier} />
        <Route exact path={CLIENT_COMPLAINT_PERCENTAGE} component={ComplaintsPercentage} />
        <Route exact path={CLIENT_FORM_DUE_DATE} component={FormDueDate} />
        <Route exact path={CLIENT_RISK_SOCRE} component={RiskScore} />
        <Route exact path={CLIENT_AUDIT_STATUS} component={AuditStatus} />
        <Route exact path={CLIENT_FORMS} component={ReportForms} />
        <Route exact path={CLIENT_AUDIT_STATUS_BY_OWNER} component={AuditStatusOwner} />
        <Route exact path={CLIENT_FORM_LIBRARY_DETAILS} component={FormBuilder} />
        <Route exact path={ADMIN_SUPPLIERS_ANSWERS_RESPONSE} component={AdminFormResponse} />
        <Route exact path={CLIENT_SUBMIT_FORM_ANSWERS} component={SubmitAnswers} />
        <Route exact path={CLIENT_COMPLETED_FORMS} component={CompletedForms} />
        <Route exact path={CLIENT_REJECTED_FORMS} component={RejectedForms} />
        <Route exact path={ADMIN_FORM_BUILDER} component={FormBuilder} />
        <Route exact path={CLIENT_SUPPLIERS_EDIT_FORM} component={EditForm} />
        <Route exact path={CLIENT_SUPPLIER_ADD_FROM_LIBRARY} component={QuestionLibrary} />
        <Route exact path={ADMIN_ADD_FROM_LIBRARY} component={QuestionLibrary} />
        <Route exact path={CLIENT_EDIT_PUBLISHED_FORM_LIBRARY_DETAILS} component={EditPublishedForms} />
        <Route exact path={CLIENT_ADD_FROM_LIBRARY_TO_PUBLISHED_FORM} component={QuestionLibrary} />
        <Route exact path={CLIENT_SUBMITTED_FORMS} component={SubmittedForms} />
        <Route exact path={CLIENT_OPENEND_FORMS} component={OpenedForms} />
        
        <Route exact path="*">
          <Redirect to={DASHBOARD} />
        </Route>
      </Switch>
    </div>
  );
};
