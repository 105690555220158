import React, { useState, useEffect } from "react";
import UserAnalyticsBox from "../../common/UserAnlyticsBox";
import formLibrary from "../../assets/img/Group 163@2x.png";
import mysupplier from "../../assets/img/sup2.png";
import searchSupplier from "../../assets/img/cli.png";
import BreadCrumb from "../BreadCrumb";
import Layout from "../Layout";
import {
  fetchDetails,
  fetchAdminDashboardDetails,
} from "../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../common/Loader";
import { ADMIN, CLIENT, SUPPLIER } from "../../common/constants";
import { Link } from "react-router-dom";
import addNew from "../../assets/img/Group 171.png";
import Footer from '../../pages/Footer'
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  const { details, loader, adminDashboardDetails } = useSelector(
    (state) => state.dashboard
  );
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };

  useEffect(() => {
    async function fetchDashboardDetails() {
      if (userDetails && userDetails.role === CLIENT) {
        await dispatch(fetchDetails({ client_id: userDetails.client_id }));
      } else {
        await dispatch(fetchAdminDashboardDetails({ supplier_id: userDetails.supplier_id }));
      }
    }

    fetchDashboardDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    form_library_count,
    approved_forms_count,
    pending_forms_count,
    rejected_forms_count,
    submitted_forms_count,
    pending_forms,
    draft_forms,
    published_forms,
    unpublished_forms,
    suppliers_count

  } = details;
  let list = [];
  let dashboardMenus = {
    "creating":[]
  }
  if (userDetails.role === CLIENT) {
    dashboardMenus ={
      "creating": [{
        "name":"Build New Form",
        "nested":[
          {
            "name":"Click here to create a new form",
            url:"/admin/formLibrary/formBuilder"
          }
        ]
      },{
        "name":"Published",
        "count": published_forms,
        "nested":[
          {
            "name":"Forms that have already been published and ready to send (unsent)",url:"/client/formLibrary",
          }
        ]
      },{
        "name":"UnPublished",
        "count": unpublished_forms,
        "nested":[
          {
            "name":"Forms that are still in draft",url:"/client/formLibrary"
          }
        ]
      }],
      "inprogress": [{
        "name":"Sent and Pending",
        "count": pending_forms,
        "nested":[
          {
            "name":"Forms published and sent out to 3rd parties",url:"/client/pendingForms"
          }
        ]
      },{
        "name":"Unopened",
        "count": pending_forms,
        "nested":[
          {
            "name":"Forms sent but unopened",url:"/client/pendingForms"
          }
        ]
      },{
        "name":"Opened",
        "count": draft_forms,
        "nested":[
          {
            "name":"Forms openend and being reviewed by 3rd parties",url:"/client/openedForms"
          }
        ]
      }],
      "completed": [{
        "name":"Rejected",
        "count": rejected_forms_count,
        "nested":[
          {
            "name":"Forms completed but rejected",url:"/client/rejectedForms"
          }
        ]
      },{
        "name":"Partial Approval",
        "count": submitted_forms_count,
        "nested":[
          {
            "name":"Returned but looking for additional information",url: "/client/submittedForms"
          }
        ]
      },{
        "name":"Approved",
        "count": approved_forms_count,
        "nested":[
          {
            "name":"Forms approved",url:"/client/approvedForms"
          }
        ]
      }]
    }
    list = [
      {
        title: "My Suppliers",
        content: "Total number of your suppliers",
        count: suppliers_count || 0,
        iconUrl: mysupplier,
        url: "/client/suppliers",
      },
      // {
      //   title: "Search Suppliers",
      //   content: "Search for suppliers",
      //   count: total_suppliers || 0,
      //   iconUrl: searchSupplier,
      //   url: "/client/suppliers/searchSuppliers/list",
      // },
      {
        title: "Approved Forms",
        content: "Total number of approved forms",
        count: approved_forms_count || 0,
        iconUrl: formLibrary,
        url: "/client/approvedForms",
      },
      {
        title: "Rejected Forms",
        content: "Total number of rejected forms",
        count: rejected_forms_count || 0,
        iconUrl: formLibrary,
        url: "/client/rejectedForms",
      },
      {
        title: "Submitted Forms",
        content: "Total number of submitted forms",
        count: submitted_forms_count || 0,
        iconUrl: formLibrary,
        url: "/client/submittedForms",
      },
      {
        title: "Pending Forms",
        content: "Total number of pending forms",
        count: pending_forms || 0,
        iconUrl: formLibrary,
        url: "/client/pendingForms",
      },
      {
        title: "Opened Forms",
        content: "Total number of opened forms",
        count: draft_forms || 0,
        iconUrl: formLibrary,
        url: "/client/openedForms",
      },
      {
        title: "Published Forms",
        content: "Total number of published forms",
        count: published_forms || 0,
        iconUrl: formLibrary,
        url: "/client/formLibrary",
      },
      {
        title: "Unpublished Forms",
        content: "Total number of pending forms",
        count: unpublished_forms || 0,
        iconUrl: formLibrary,
        url: "/client/formLibrary",
      },
    ];
  } else if (userDetails.role === SUPPLIER) {
    list = [
      {
        title: "Clients",
        content: "Total number of connected clients",
        count: adminDashboardDetails.clients || 0,
        iconUrl: mysupplier,
        url: "/supplier/clients",
      },
      {
        title: "Pending Forms",
        content: "Total number of pending forms",
        count: adminDashboardDetails.pending_forms || 0,
        iconUrl: mysupplier,
        url: "/supplier/pendingForms",
      },
    ];
  } else {
    list = [
      {
        title: "Clients",
        content: "Total number of clients on the platform",
        count: adminDashboardDetails.clients || 0,
        iconUrl: mysupplier,
        url: "/admin/clients",
      },
      {
        title: "Suppliers",
        content: "Total number of suppliers",
        count: adminDashboardDetails.suppliers || 0,
        iconUrl: searchSupplier,
        url: "/admin/suppliers",
      },
      {
        title: "Form Library",
        content: "Total number of published forms",
        count: adminDashboardDetails.form_library_count || 0,
        iconUrl: formLibrary,
        url: "/admin/formLibrary",
      },
      {
        title: "Sent Forms",
        content: "Total number of sent forms",
        count: adminDashboardDetails.sent_forms || 0,
        iconUrl: mysupplier,
        url: "/admin/sentForms",
      },
    ];
  }

  return (
    <div className="page-wrapper">
      <Layout onClick={onSideMenu} />
      <PageLoader loader={loader}>
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="spacer"></div>
          {userDetails.role === CLIENT ? (
                      <>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  text-center  ">
                          <div className="main-box creating">
                            <b>Creating</b>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center ">
                           <div className="main-box in-progress">
                            <b>In Progress</b>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center ">
                        <div className="main-box completed">
                            <b>Completed</b>
                          </div>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="sub-box creating ">
                          <ul>
                            {dashboardMenus.creating.map(menuObj=>(
                              <>
                                <li><b>{menuObj.name}</b>  &nbsp;&nbsp;{ menuObj.name === "Build New Form" ? (
                                  <>
                                    <Link className="creating-link" title={"Create New Form"} to="/admin/formLibrary/formBuilder">
                                        <i style={{fontSize:"24px",cursor:"pointer"}} class="fa fa-plus-circle"></i>
                                    </Link>
                                  </>
                                ):(
                                  <>
                                    <div className="badge badge-light">{(menuObj?.count || 0 )}</div>
                                  </>
                                )} 
                                </li>
                                <ul>
                                {menuObj.nested.map(nestedObj=>(
                                    <>
                                      {/* <li><b>{nestedObj.name}</b> </li> */}
                                      <li>
                                        <Link className="creating-link" to={nestedObj.url}>{nestedObj.name}</Link>
                                      </li>
                                    </>
                                  ))}
                                </ul>
                                <div className="spacer"></div>
                              </>
                            ))}
                          </ul>
                        </div>
                        
                      </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="sub-box in-progress ">
                        <ul>
                          {dashboardMenus.inprogress.map(menuObj=>(
                            <>
                              <li><b>{menuObj.name}</b>  &nbsp;&nbsp;<div className="badge badge-light">{(menuObj?.count || 0 )}</div></li>
                              <ul>
                              {menuObj.nested.map(nestedObj=>(
                                  <>
                                    {/* <li><b>{nestedObj.name}</b> </li> */}
                                    <li>
                                      <Link className="inprogress-link" to={nestedObj.url}>{nestedObj.name}</Link>
                                    </li>
                                  </>
                                ))}
                              </ul>
                              <div className="spacer"></div>
                            </>
                          ))}
                        </ul>
                        </div>
                        
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="sub-box completed ">
                          <ul>
                            {dashboardMenus.completed.map(menuObj=>(
                              <>
                                <li><b>{menuObj.name}</b>  &nbsp;&nbsp;<div className="badge badge-light">{(menuObj?.count || 0 )}</div></li>
                                <ul>
                                {menuObj.nested.map(nestedObj=>(
                                    <>
                                      {/* <li><b>{nestedObj.name}</b> </li> */}
                                      <li>
                                        <Link className="creating-link" to={nestedObj.url}>{nestedObj.name}</Link>
                                      </li>
                                    </>
                                  ))}
                                </ul>
                                <div className="spacer"></div>
                              </>
                            ))}
                          </ul>
                        </div>
                        
                        </div>
                      </div>
                      </>
                    )
          : (
            <>
          <div className="row">
              {list.map((obj, key) => (
              <>
                {userDetails.role === ADMIN ? (
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
                    <UserAnalyticsBox data={obj} key={key} />
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                  </div>
                ) : (
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
                    <UserAnalyticsBox data={obj} key={key} />
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                  </div>
                )}
              </>
            ))}
          </div>

            </>
          )}
            
          <div className="flex-grow-1"></div>
                    <Footer /> 
        </div>
      </PageLoader>
      
    </div>
  );
};

export default Dashboard;
