import React from "react";
import DataTable from "../../components/Tables/DataTable";
import { Tab, Nav } from "react-bootstrap";
import AgGridTable from "../../components/Tables/agGridTable";
const FormsList = (props) => {
  const { actionsListComponent} = props;
  return (
    <>
              {/* <DataTable header={props.globalHeader} data={props.data} /> */}
              {/* <AgGridTable 
                columnDefs={props.globalHeader}
                rowData ={props.data}
                frameworkComponents={{
                  actionsListComponent
                }}
                /> */}
                {
                  props.data.length ? (
                    <>
                        <AgGridTable 
                      columnDefs={props.globalHeader}
                      rowData ={props.data}
                      frameworkComponents={{
                        actionsListComponent
                      }}
                      defaultColDef={{
                        resizable: true,minWidth: 100}}
                      // pagination={props.pagination}
                      // datasource={datasource}
                      // isPaginationRequired={true}
                      // actionType={SET_STUDENTS_LIST}
                      fetchParamsList={props.fetchParamsList}
                      handleAgGridRef={props.handleAgGridRef}
                      {...props}
                      />
                    </>
                  ) : null 
                }
    </>
  );
};

export default FormsList;
