import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Select from "react-select";
import addNew from "../../assets/img/Group 171.png";
import CreateNewQuestion from "./createNewQuestion";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  createQuestion,
  fetchFormData,
  updateFormData,
  resetFormData,
  addQuestionFromLibary,
  deleteFormQuestion,
  resetQuestionFormLibraryData,
  resetFormLevelQuestionData,
  fetchSectionsList,
  addCustomSection,
  setSelectedSectionAction,
  questionDetailsAction,
  resetQuestionData,
  updateQuestionAction,
  addSectionToFormAction,
  reorderSectionsAction,
  reOrderQuestionsAction,
  addCustomValidationAction,
  submitAnswersAction,
  getSupplierFormResponseAction,
  deleteSection,
  formPublishAction,
  getSuppliersListAction,
  sendFormtoSupplierAction,
  questionReOrderAction,
  moveQuestionToSectionAction,
} from "../../redux/actions/formLibraryAction";
import FormName from "./formName";
import Questions from "../QuestionLibrary/Questions";
import {
  ADMIN_FORM_ANSWERS,
  CHOOSE_MORE_THAN_ONE,
  CLIENT,
  FORM_BUILDER,
  SUBMIT_ANSWERS,
  APPROVED,
  REJECTED,
  ADMIN_SENT_FORM_ANSWERS,
  MULTIPLE_CHOICE_WITH,
  PUBLISHED,
  UN_PUBLISHED,
  SUBMITTED,
  ADMIN,
  SUPPLIER,
  NEEDS_FOLLOWUP,
  IN_REVIEW,
  NEEDS_FOLLOWUP_ANSWERS,
  NOT_SUBMITTED,
  NOT_STARTED,
  DRAFT_ANSWERS,
  DRAFT,EDIT_FORM, EDIT_PUBLISHED_FORM, IN_PROGRESS_ANSWERS,FILE_TYPE
} from "../../common/constants";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import {
  approveFormAction,
  rejectFormAction,
  resetActiveSectionNameAction,
} from "../../redux/actions/questionLibraryAction";
import { ERROR, ToastMessage } from "../../common/ToastMessage";
import SendForm from "../FormLibrary/sendForm";
import { sendFormToSupplierAction } from "../../redux/actions/suppliersAction";
import { saveAs } from "file-saver";


const answersFormat = (data)=>{
  let formatAnswerObj = {}
  Object.keys(data).forEach(obj=>{
    formatAnswerObj[`${obj}`]={}
    data[`${obj}`].forEach(question=>{
      let userObj ={...question}
          userObj.sectionName = obj;
          userObj.type =  userObj.options_type;
          if(userObj.options_type === MULTIPLE_CHOICE_WITH){
            // userObj.value = userObj.answer ? userObj.answer : null ;
            userObj.value =  userObj.questions_options.filter(optionData=> {
              if(userObj.answer && userObj.answer.includes(optionData.option_number)){
                optionData.value = optionData.option_number
                optionData.question_option_id = optionData.id;
                return optionData;
              }
            })
          }else{
            userObj.value = userObj.answer ? userObj.answer[0] : null ;
          }
          if(userObj.options_type === CHOOSE_MORE_THAN_ONE){
            userObj.label = userObj.questions_options.filter(optionData=> optionData.option_number === userObj.value).length > 0 ? userObj.questions_options.filter(optionData=> optionData.option_number === userObj.value)[0].option_number : null ;
            userObj.question_option_id = userObj.questions_options.filter(optionData=> optionData.option_number === userObj.value).length > 0 ? userObj.questions_options.filter(optionData=> optionData.option_number === userObj.value)[0].id : null ;
          }
      userObj.question_id = userObj.id;
      formatAnswerObj[`${obj}`][`${question.id}`]= userObj;
      // formatAnswerObj[`${obj}`][`${question.id}`]={
      //   ...question
      // }
       
    })
  })
  return formatAnswerObj;
    // IT Security:
    // 224:
    // question_id: 224
    // question_option_id: 267
    // type: "Choose single response in a dropdown"
    // value: "Books"
}

const CreateForm = (props) => {
  const { redirectFrom } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const formId = params.id;
  const supplier_id = params.supplier_id;
  const history = useHistory();
  const {
    formData,
    formQuestions,
    sectionList,
    selectedSectionData,
    questionData,
    questionSectionsList,
    editableSectionsList,
    is_published,
    formStatus,
    status_update_reasonses,
    supplier_name
  } = useSelector((state) => state.form);
  const { activeSectionName } = useSelector((state) => state.question);
  const preExpandedItems =
    activeSectionName.length > 0 ? activeSectionName : questionSectionsList;

  const { userDetails } = useSelector((state) => state.user);
  const [sectionsList, setSectionsList] = useState([]);
  const [sectionQuestion, setSectionQuestion] = useState(null);
  const [selectedSection, setSelectedSection] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFormNameOpen, setIsFormNameOpen] = useState(false);
  const [formType, setFormType] = useState("CreateForm");
  const [sectionOptions, setSectionOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [sectionsOrder, setSectionsOrder] = useState([]);
  const [answersObj, setAnswersObj] = useState({});
  const [preExpanded, setPreExpanded] = useState(preExpandedItems);
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [isLastCollapsedItem, setIsLastCollapsedItem] = useState(false);
  const [editableSection, setEditableSection] = useState(null);
  const [sendFormOpen, setSendFormOpen] = useState(false);
  const [formState, setFormState] = useState(null);
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Message is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(async () => {
    dispatch(resetFormLevelQuestionData());
    if (formId) {
      if (
        (params.supplier_id && formId) ||
        redirectFrom === ADMIN_FORM_ANSWERS ||
        redirectFrom === ADMIN_SENT_FORM_ANSWERS || redirectFrom === NEEDS_FOLLOWUP_ANSWERS || redirectFrom === DRAFT_ANSWERS || redirectFrom === IN_PROGRESS_ANSWERS
      ) {
        
        await dispatch(
          getSupplierFormResponseAction({
            form_id: formId,
            supplier_id: params.supplier_id,
          })
        );
      } else {
        let reqObj = {form_id: formId}
        console.log(userDetails)
        if(userDetails.role === "Supplier"){
          reqObj["supplier_id"] = userDetails.id
        }
        await dispatch(
          fetchFormData(reqObj)
        );
      }
      await dispatch(updateFormData({ id: formId }));
    } else {
      await dispatch(resetFormData());
      await dispatch(resetFormLevelQuestionData());
    }
    await dispatch(resetActiveSectionNameAction());
    await dispatch(fetchSectionsList());
    await dispatch(resetQuestionFormLibraryData());
  }, []);
  useEffect(() => {
    const data = sectionsList.map((obj) => obj.value);
    // if(Object.keys(formQuestions).length === 0){

    // }else{
    selectedSectionData.filter((obj) => {
      if (Object.keys(formQuestions).includes(obj)) {
      } else {
        const reqData = {};
        reqData[`${obj}`] = [];
        dispatch(addCustomSection(reqData));
      }
    });
    setSectionsList([]);
    dispatch(setSelectedSectionAction([]));
    // }
    setSectionsOrder(Object.keys(formQuestions));
    if([NEEDS_FOLLOWUP_ANSWERS,DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)){
      const result = answersFormat(formQuestions);
      setAnswersObj(result);
    }
  }, [formQuestions]);
  useEffect(() => {
    setSectionOptions(
      sectionList.map((obj) => {
        return { value: obj, label: obj };
      })
    );
  }, [sectionList]);

  const closeModal = async (value) => {
    setIsOpen(value);
    setIsFormNameOpen(value);
    setSelectedQuestion(null);
    await dispatch(fetchSectionsList());
    dispatch(resetQuestionData());
    setSendFormOpen(value);
  };
  const handleDropdownAction = (value, sectionQuestion) => {
    if (value === "createNew") {
      setSelectedQuestion(null);
      dispatch(resetQuestionData());
      setIsOpen(true);
    } else {
      const requestedParams = {
        form_id: formData.id,
        section: sectionQuestion,
      };
      dispatch(addQuestionFromLibary(requestedParams));
      if (userDetails && userDetails.role === CLIENT) {
        // history.push("/client/questionLibrary");
        if(redirectFrom === EDIT_FORM){
          history.push(
            `/client/suplier/${supplier_id}/formLibrary/formBuilder/${formId}/questionLibrary/${sectionQuestion}`
          );
        }else if(redirectFrom === EDIT_PUBLISHED_FORM){
          history.push(
            `/client/formLibrary/${EDIT_PUBLISHED_FORM}/formBuilder/${formId}/questionLibrary/${sectionQuestion}`
          );
        }else{
          history.push(
            `/admin/formLibrary/formBuilder/${formId}/questionLibrary/${sectionQuestion}`
          );
        }
        
      } else {
        history.push(
          `/admin/formLibrary/formBuilder/${formId}/questionLibrary/${sectionQuestion}`
        );
        // history.push("/admin/questionLibrary");
      }
    }
    setSectionQuestion(sectionQuestion);
  };
  const create = async (selectedOption) => {
    const requestedParams = {
      ...selectedOption,
      client_id: userDetails.client_id,
      level: "form",
      form_id: formData.id,
      section: sectionQuestion,
      vendor_id: supplier_id
    }
    const reqObj = new FormData();
        if(Object.keys(selectedOption.fileObj).length > 0){
          reqObj.append('attachment', selectedOption.fileObj, selectedOption.fileObj.name);
        }
        await Object.keys(requestedParams).forEach(obj=>{
          reqObj.append(`${obj}`, requestedParams[`${obj}`]);
        })
    await dispatch(
      /*
      createQuestion({
        ...selectedOption,
        client_id: userDetails.client_id,
        level: "form",
        form_id: formData.id,
        section: sectionQuestion,
        vendor_id: supplier_id
      })
      */
      createQuestion(reqObj,formData.id)
    );
    if (selectedOption.isModalClose) {
      setIsOpen(false);
    }
  };
  const formNameClickAction = () => {
    setFormType("Create");
    setIsFormNameOpen(true);
  };
  const handleSecion = () => {
    if (formData.name) {
      setFormType("Section");
      setIsFormNameOpen(true);
    } else {
      ToastMessage(ERROR, "Please save the form before creating a section");
    }
  };
  const handleSectionDropdown = async (value) => {
    if (value) {
      setSelectedSection(value);

      if (Object.keys(formQuestions).includes(value.label)) {
      } else {
        const data = [...sectionsList];
        data.push(value);
        setSectionsList(data);
        setSelectedSection([]);
        const reqData = {};
        reqData[`${value.label}`] = [];
        const newData = selectedSectionData.concat(
          data.map((obj) => obj.value)
        );
        await dispatch(addCustomSection(reqData));
        await dispatch(setSelectedSectionAction(newData));
        await dispatch(
          addSectionToFormAction({
            form_id: formData.id,
            name: value.label,
          })
        );
      }
    } else {
      setSelectedSection([]);
    }
  };
  const deleteAction = async (value) => {
    await dispatch(
      deleteFormQuestion({
        question_id: value.id,
        section: value.section,
        form_id: formData.id,
      })
    );
  };
  const editAction = async (value) => {
    dispatch(resetQuestionData());
    setSelectedQuestion(value.id);
    await dispatch(
      questionDetailsAction({
        question_id: value.id,
      })
    );
    setIsOpen(true);
  };
  const downloadAction = async (value) => {
    saveAs(value.attachment);
  };
  const localQuestionActions = [
    {
      name: "Download",
      icon: "fa fa-download",
      onClick: downloadAction,
    },
    {
      name: "Edit",
      icon: "fa fa-edit",
      onClick: editAction,
    },
    {
      name: "Delete",
      icon: "fa fa-trash-o",
      onClick: deleteAction,
    },
  ];
  const updateQuestion = async (selectedOption) => {
    const requestedParams = {
      ...selectedOption,
      client_id: userDetails.client_id,
      level: "form",
      form_id: formData.id,
      section: sectionQuestion,
      vendor_id: supplier_id
    }
    const reqObj = new FormData();
        if(Object.keys(selectedOption.fileObj).length > 0){
          reqObj.append('attachment', selectedOption.fileObj, selectedOption.fileObj.name);
        }
        await Object.keys(requestedParams).forEach(obj=>{
          reqObj.append(`${obj}`, requestedParams[`${obj}`]);
        })

    await dispatch(
      /*
      updateQuestionAction({
        ...selectedOption,
        client_id: userDetails.client_id,
        level: "form",
        form_id: formData.id,
        section: sectionQuestion,
        vendor_id: supplier_id
      })
      */
     updateQuestionAction(reqObj,formData.id)
    );
    setIsOpen(false);
  };
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppableItem") {
      const items = reorder(
        sectionsOrder,
        result.source.index,
        result.destination.index
      );
      const position = result.destination.index - result.source.index;
      // const sectionName = result.draggableId.split(`-${result.source.index}`)
      const sectionName = result.draggableId;
      const reqParams = {
        form_id: formId,
        number_of_positions_change: position,
        name: sectionName,
      };
      setSectionsOrder(items);
      dispatch(reorderSectionsAction(reqParams));
    } else if (result.type === "droppableSubItem") {
      // const itemSubItemMap = this.state.items.reduce((acc, item) => {
      //   acc[item.id] = item.subItems;
      //   return acc;
      // }, {});

      // const sourceParentId = parseInt(result.source.droppableId);
      // const destParentId = parseInt(result.destination.droppableId);
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;
      // const sourceSubItems = itemSubItemMap[sourceParentId];
      // const destSubItems = itemSubItemMap[destParentId];

      // let newItems = [...this.state.items];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const sectionName = result.source.droppableId;
        const items = reorder(
          formQuestions[`${sectionName}`],
          result.source.index,
          result.destination.index
        );
        const position = result.destination.index - result.source.index;
        const reqParams = {
          question_id: parseInt(result.draggableId),
          number_of_positions_change: position,
          section: sectionName,
        };
        formQuestions[`${sectionName}`] = [...items];
        // setQuestionsList(items);
        // console.log(questionsList)
        // dispatch(questionReOrderAction(formQuestions))
        questionReorder(reqParams);
      } else {
        const old_section = result.source.droppableId;
        const new_section = result.destination.droppableId;
        let reqParams = {
          old_section,
          new_section,
          form_id: formId,
          question_id: result.draggableId,
        };
        dispatch(moveQuestionToSectionAction(reqParams));
      }
    }
  };
  const questionReorder = async (params) => {
    const reqParams = {
      ...params,
      form_id: formId,
    };
    dispatch(reOrderQuestionsAction(reqParams));
  };
  const sendAnswers = (questionObj) => {
    let questionData = { ...answersObj };
    if (questionData[`${questionObj.sectionName}`]) {
      const moreThanOneAnswers =
        questionData[`${questionObj.sectionName}`][
          `${questionObj.question_id}`
        ];
      if (moreThanOneAnswers && questionObj.type === MULTIPLE_CHOICE_WITH) {
        const moreThanOneAnswersIndex = moreThanOneAnswers.value
          .map((obj) => obj.question_option_id)
          .indexOf(questionObj.question_option_id);
        if (moreThanOneAnswersIndex === -1) {
          moreThanOneAnswers.value = moreThanOneAnswers.value.concat(
            questionObj.value
          );
        } else {
          moreThanOneAnswers.value.splice(moreThanOneAnswersIndex, 1);
          if (moreThanOneAnswers.value.length === 0) {
            delete questionData[`${questionObj.sectionName}`][
              `${questionObj.question_id}`
            ];
          } else {
            questionData[`${questionObj.sectionName}`][
              `${questionObj.question_id}`
            ] = {
              question_id: questionObj.question_id,
              value: moreThanOneAnswers.value,
              type: questionObj.type,
              attachment: questionObj?.callIngFrom === "UploadFile" ?  questionObj.attachment : questionData[`${questionObj.sectionName}`][`${questionObj.question_id}`]["attachment"]
            };
          }
        }
      } else {
        let sectionName = questionData[`${questionObj.sectionName}`]
        questionData[`${questionObj.sectionName}`][`${questionObj.question_id}`] = {
          question_id: questionObj.question_id,
          // value: questionObj.value,
          value : questionObj?.callIngFrom !== "UploadFile" ?  questionObj.value : ( sectionName[`${questionObj.question_id}`] ? sectionName[`${questionObj.question_id}`]["value"] : null) ,
          type: questionObj.type,
          question_option_id: questionObj.question_option_id,
          attachment: questionObj?.callIngFrom === "UploadFile" ?  questionObj.attachment : ( sectionName[`${questionObj.question_id}`] ? sectionName[`${questionObj.question_id}`]["attachment"] : null)
          // attachment: questionObj?.callIngFrom === "UploadFile" ?  questionObj.attachment : null
        };
      }
    } else {
      questionData[`${questionObj.sectionName}`] = {};
      questionData[`${questionObj.sectionName}`][`${questionObj.question_id}`] =
        {
          question_id: questionObj.question_id,
          value: questionObj.value,
          type: questionObj.type,
          question_option_id: questionObj.question_option_id,
          attachment: questionObj.attachment
        };
    }
    if (Object.keys(questionData[`${questionObj.sectionName}`]).length === 0) {
      delete questionData[`${questionObj.sectionName}`];
    }
    setAnswersObj(questionData);
    // submitAnswers();
  };
  const getReqParams = ()=>{
    const reqData = [];
    Object.keys(answersObj).forEach((obj) => {
      let sectionObj = {};
      sectionObj[`${obj}`] = [];
      Object.values(answersObj[`${obj}`]).forEach((childObj) => {
        const response =
          childObj.type === MULTIPLE_CHOICE_WITH
            ? childObj.value.map((obj) => obj.value)
            : childObj.value;
        let params = {
          response: response,
          question_id: childObj.question_id,
          type: childObj.type,
          attachment: childObj.attachment
        };
        sectionObj[`${obj}`].push(params);
      });
      reqData.push(sectionObj);
    });
    let params = {
      form_id: formId,
      form_response: reqData
    }
    return params;
  }

  const submit = async(reqParams)=>{
    const form_data = new FormData();
    if(userDetails.role === SUPPLIER){
      reqParams['supplier_id'] = userDetails.supplier_id
      form_data.append("supplier_id",userDetails.supplier_id)
    }
    Object.keys(reqParams).forEach(keyParams=>{
        if(keyParams === 'form_response'){
          form_data.append(`${keyParams}`,JSON.stringify(reqParams[`${keyParams}`]))
        }else{  
          form_data.append(`${keyParams}`,reqParams[`${keyParams}`])

        }
    })
    let attachmentQuestions = []

    Object.keys(formQuestions).forEach(key=>{
      const attachments = formQuestions[`${key}`].filter(obj=> obj.question_type === FILE_TYPE)
      attachmentQuestions = attachmentQuestions.concat(attachments)
    })


    let responseAttachements = [];
    reqParams.form_response.forEach((formResObj,index)=>{
      Object.keys(formResObj).forEach((sectionObj,sectionIndex)=>{
        formResObj[`${sectionObj}`].forEach((questionRes,questionIndex)=>{
          // form_data.append(`form_response[${index}][${sectionObj}][${sectionIndex}]["question_id"]`,questionRes.question_id)
          // form_data.append(`form_response[${index}][${sectionObj}][${sectionIndex}]["type"]`,questionRes.type)
          // if(questionRes.type === FILE_TYPE){
          if(questionRes?.type === FILE_TYPE && questionRes?.attachment){
            responseAttachements.push(questionRes)
            // isFileAttached = false;
            // console.log("Please upload")
            // ToastMessage(ERROR, "Please upload a file");
          }
          if(questionRes?.attachment){
            // form_data.append(`form_response[${index}][${sectionObj}][${sectionIndex}]["response"]`, questionRes.response, questionRes.response.name);
            let sectionName = sectionObj.replaceAll(" ","{{key}}")
            form_data.append(`${sectionName}_${questionRes.question_id}_attachment`, questionRes.attachment, questionRes.attachment.name);
          }else{
            // form_data.append(`form_response[${index}][${sectionObj}][${sectionIndex}]["response"]`,questionRes.response)
          }
        })
      })
    })
    
    if((attachmentQuestions.length === responseAttachements.length ) || reqParams?.status === DRAFT){
      await dispatch(
        submitAnswersAction(form_data)
      );
    }else{
      ToastMessage(ERROR, "Please upload a files for attachment type questions");
    }

  }

  const draftAnswers =async(status)=>{
    let params = await getReqParams();
    let reqParams = {...params,status}
    if(userDetails.role === SUPPLIER){
      reqParams['supplier_id'] = userDetails.supplier_id
    }
    await submit(reqParams)
    // await dispatch(
    //   submitAnswersAction(reqParams)
    // );
  }

  const submitAnswers = async () => {
     
    let params = await getReqParams();
    let reqParams = {...params}
    await submit(reqParams)
   
  };
  const handleCollapse = async (data) => {
    // setCollapsedItems(data)
    setPreExpanded([]);
    await dispatch(resetActiveSectionNameAction());
  };
  const approveForm = async () => {
    setFormState(null);
    await dispatch(
      approveFormAction({
        supplier_id: params.supplier_id,
        form_id: formId,
        status: APPROVED,
      })
    );
  };
  const rejectForm = async () => {
    // await dispatch(
    //   rejectFormAction({
    //     supplier_id: params.supplier_id,
    //     form_id: formId,
    //     status: REJECTED,
    //   })
    // );
    setFormState(REJECTED);
  };
  const startReview = async () => {
    await dispatch(
      approveFormAction({
        supplier_id: params.supplier_id,
        form_id: formId,
        status: IN_REVIEW,
      })
    );
  };
  const requiredFollowup = async () => {
    setFormState(NEEDS_FOLLOWUP);
  };

  const onClickDropdown = (e) => {
    e.stopPropagation();
    // alert("I'm not expanding!");
  };
  const editClick = (e, sectionName) => {
    setEditableSection(sectionName);
    setFormType("Update Section")
    setIsFormNameOpen(true);
    e.stopPropagation();
    // alert("I'm not expanding!");
  };
  const deleteClick = async (e, sectionName) => {
    await dispatch(
      deleteSection({
        id: formId,
        name: sectionName,
      })
    );
    e.stopPropagation();
    // alert("I'm not expanding!");
  };
  const sendPublishAction = async (data) => {
    await dispatch(
      formPublishAction(
        {
          form_id: formId,
          status: PUBLISHED,
        },
        []
      )
    );
  };
  const sendUnPublishAction = async (data) => {
    await dispatch(
      formPublishAction(
        {
          form_id: formId,
          status: UN_PUBLISHED,
        },
        []
      )
    );
  };
  const sendAction = async (data) => {
    await dispatch(getSuppliersListAction({ form_id: formId }));
    setSendFormOpen(!sendFormOpen);
  };
  const sendSupplierForm = async (data) => {
    const params = {
      form_id: formId,
      due_date: data.due_date,
      email: data.email,
      additional_message: data.message,
      supplier_ids: data.supplier_ids,
      supplier_email: data.supplier_email,
    };
    await dispatch(sendFormtoSupplierAction({ ...params }));
    closeModal(false);
  };
  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    // width: 200
  });
  const renderApproveButtons =(param)=> {
    switch(userDetails.role) {
      case ADMIN:
        return (<>
        {formStatus === SUBMITTED ? (
          <>
                          <Button
                            variant="btn app-default-button btn-sm btn-primary"
                            type="button"
                            onClick={startReview}
                            style={{ marginRight: "10px" }}
                          >
                            Start Review
                          </Button>
          </>
        ) : null }
        { formStatus === IN_REVIEW || formStatus === NEEDS_FOLLOWUP ? (
            <>
            <Button
                 variant="btn app-default-button btn-sm approved"
                 type="button"
                 onClick={approveForm}
                 style={{ marginRight: "10px" }}
               >
                 Approve
               </Button>
               <Button
                 variant="btn  btn-sm needs-followup"
                 type="button"
                 onClick={requiredFollowup}
                 style={{ marginRight: "10px" }}
               >
                                Require Followup
               </Button>
               <Button
                 variant="btn  btn-sm btn-danger"
                 type="button"
                 onClick={rejectForm}
               >
                 Reject
               </Button>
            </>
          ): null }
                         
                </>);
      case CLIENT:
        return (<>
          {formStatus === SUBMITTED ? (
            <> <Button
                            variant="btn app-default-button btn-sm btn-primary"
                            type="button"
                            onClick={startReview}
                            style={{ marginRight: "10px" }}
                          >
                            Start Review
                          </Button>
            </>
          ) : null  } 
           { formStatus === IN_REVIEW || formStatus === NEEDS_FOLLOWUP ? (
            <>
            <Button
                 variant="btn app-default-button btn-sm approved"
                 type="button"
                 onClick={approveForm}
                 style={{ marginRight: "10px" }}
               >
                 Approve
               </Button>
               <Button
                 variant="btn  btn-sm needs-followup"
                 type="button"
                 onClick={requiredFollowup}
                 style={{ marginRight: "10px" }}
               >
                                Require Followup
               </Button>
               <Button
                 variant="btn  btn-sm btn-danger"
                 type="button"
                 onClick={rejectForm}
               >
                 Reject
               </Button>
            </>
          ): null }
                
          </>);
      case SUPPLIER:
        return (<>
                      {/* <Button
                        variant="btn app-default-button btn-sm btn-primary"
                        type="button"
                        onClick={approveForm}
                        style={{ marginRight: "10px" }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="btn  btn-sm btn-danger"
                        type="button"
                        onClick={rejectForm}
                      >
                        Reject
                      </Button> */}
            </>);
      default:
        return '';
    }
  }
  const onSubmit = async (data) => {
    await dispatch(
      approveFormAction({
        supplier_id: params.supplier_id,
        form_id: formId,
        status: formState,
        ...data
      })
    );
    setFormState(null);
      reset();
    
  };
  const error = async(data)=>{
  }

  return (
    <div className="page-wrapper">
      <FormName
        isOpen={isFormNameOpen}
        closeModal={closeModal}
        id={formData.id}
        formType={formType}
        editableSection={editableSection}
      />
      <CreateNewQuestion
        isOpen={isOpen}
        closeModal={closeModal}
        createQuestion={create}
        selectedQuestion={selectedQuestion}
        updateQuestion={updateQuestion}
        redirectFrom={FORM_BUILDER}
      />
      <SendForm
        isOpen={sendFormOpen}
        closeModal={closeModal}
        send={sendSupplierForm}
      />
      {Object.keys(formData).length > 0 && [FORM_BUILDER,EDIT_FORM,EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1 ? (
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"></div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Select
              value={selectedSection}
              onChange={handleSectionDropdown}
              options={sectionOptions}
              placeholder="Add Section"
              isClearable={true}
              isDisabled={Object.keys(formData).length === 0 || ( [EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1 ? false : is_published )  }
              // isMulti={true}
            />
          </div>
        </div>
      ) : null}
      {redirectFrom === ADMIN_FORM_ANSWERS  ? (
        <>
          <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style={{ textAlign: "right", marginBottom: "10px" }}
            >
              {renderApproveButtons()}
            </div>
          </div>
        </>
      ) : null}

 { [ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom)  && formStatus !== SUBMITTED && formState ===null ? (
        <>
          <Accordion allowZeroExpanded preExpanded={['a']}>
            <AccordionItem uuid="a">
              <AccordionItemHeading >
                <AccordionItemButton className={`accordion__button ${formStatus  === NEEDS_FOLLOWUP ? "NeedsFollowup" : formStatus}`}>
                  <div className={` row section-adjustment ${formStatus  === NEEDS_FOLLOWUP ? "NeedsFollowup" : formStatus}` }>
                    <div className="col-10 section-name-details">
                      <div
                        style={
                          redirectFrom === FORM_BUILDER
                            ? { fontSize: "20px" }
                            : { fontSize: "20px", "padding-bottom": "10px" }
                        }
                      >
                        {" "}
                        <b>{formStatus}</b>{" "} ( {supplier_name} )
                      </div>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  {status_update_reasonses.map(obj=>(
                    <>
                    <div className="row">
                      <div className="col-12">
                        <b><u>{formStatus === NEEDS_FOLLOWUP ? "Followup Date" : `${formStatus} Date` }</u></b> : &nbsp;&nbsp; {obj.submitted_date}
                      </div>
                    </div>
                    {
                      [NEEDS_FOLLOWUP,REJECTED].includes(formStatus) ? (
                        <>
                        <div className="row">
                          <div className="col-4">
                            <b><u>{formStatus === NEEDS_FOLLOWUP ? "Followup Instructions" : "Rejected Reason" }</u></b> : &nbsp;&nbsp; {obj.reason}
                          </div>
                        </div>
                        </>
                      ) : null 
                    }
                    <div className="spacer"></div>
                    </>
                  ))}
                  {/* {[APPROVED,IN_REVIEW,SUBMITTED,NOT_STARTED].includes(formStatus) ? (
                    <>
                    <div className="row">
                      <div className="col-12">
                        <b><u>{formStatus}</u></b> : &nbsp;&nbsp; 2021-04-2022
                      </div>
                    </div>
                    <div className="spacer"></div>
                    </>
                  ) : null } */}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </>
      ) : null}

      {redirectFrom === ADMIN_FORM_ANSWERS  && [CLIENT,ADMIN].includes(userDetails.role)  && [NEEDS_FOLLOWUP,REJECTED].includes(formState)  ? (
        <>
          <Accordion allowZeroExpanded preExpanded={['a']}>
            <AccordionItem uuid="a">
              <AccordionItemHeading >
                <AccordionItemButton className={`accordion__button ${formState  === NEEDS_FOLLOWUP ? "NeedsFollowup" : formState}`}>
                  <div className={` row section-adjustment ${formState  === NEEDS_FOLLOWUP ? "NeedsFollowup" : formState}` } >
                    <div className="col-10 section-name-details">
                      <div
                        style={
                          redirectFrom === FORM_BUILDER
                            ? { fontSize: "20px" }
                            : { fontSize: "20px", "padding-bottom": "10px" }
                        }
                      >
                        {" "}
                        <b>{formState}</b>{" "}
                      </div>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                   <div>
                    <Form
                      noValidate
                      // validated={validated}
                      // onSubmit={e => submitForm(e)}
                      onSubmit={handleSubmit(onSubmit,error)}
                    >
                    <div className="row">
                      <div className="col-12">
                      <Form.Group controlId="formBasicPassword">
                      <Form.Label><u>{formState === NEEDS_FOLLOWUP ? "Review Instructions" : "Reason" } </u></Form.Label>
                        <textarea placeholder={formState === NEEDS_FOLLOWUP ? "Review Instructions" : "Reason" } id="exampleFormControlTextarea1" rows="3"  name="reason" {...register("reason")} className={`form-control ${errors.reason ? "is-invalid" : ""}`}></textarea>
                        <span className="invalid-feedback">
                          {errors.reason?.reason}
                        </span>
                      </Form.Group>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="row">
                      <div className="col-12 text-right">
                      <Button
                            variant="btn app-default-button btn btn-primary"
                            type="submit"
                          >
                         Submit
                            
                      </Button>
                      </div>
                    </div>
                    </Form>
                  </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </>
      ) : null}
      
      <div className="spacer"></div>

      

<div className="spacer"></div>
      

      <div className="card">
        <div
          className="card-header"
          style={{
            backgroundColor: "#707070",
            color: "white",
            // paddingTop: "15px",
            paddingBottom: "10px",
          }}
        >
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
              <div
                className="card-title"
                style={{
                  color: "white",
                  fontSize: "24px",
                  cursor: "pointer",
                  position: "relative",
                  top: "7px",
                }}
                onClick={
                  redirectFrom === FORM_BUILDER ? formNameClickAction : null
                }
              >
                &nbsp;&nbsp;
                {formData && formData.name ? formData.name : "Enter Form Name"}
                &nbsp;{" "}
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
              style={{ top: "7px", right: "12px" }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"></div>
                <div
                  className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
                  style={{ textAlign: "right" }}
                >
                  {[FORM_BUILDER,EDIT_FORM,EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1 ? (
                    <>
                      {formId && formData && !is_published && redirectFrom === FORM_BUILDER ? (
                        <div
                          style={{
                            top: "7px",
                            paddingRight: "12px",
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <i
                            class="fa fa-check-circle app-icons app-icons-publish"
                            aria-hidden="true"
                            title="Publish"
                            onClick={sendPublishAction}
                          ></i>
                        </div>
                      ) : null}

                      {formId && formData && is_published ? (
                        <div
                          style={{
                            top: "13px",
                            paddingRight: "12px",
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <i
                            class="fa fa-ban app-icons app-icons-publish"
                            aria-hidden="true"
                            title="Unpublish"
                            onClick={sendUnPublishAction}
                          ></i>
                        </div>
                      ) : null}

                      {formId && formData && is_published ? (
                        <div
                          style={{
                            top: "11px",
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <i
                            class="fa fa-paper-plane app-icons app-icons-send-form"
                            aria-hidden="true"
                            title="Send Form To Supplier"
                            onClick={sendAction}
                          ></i>
                        </div>
                      ) : null}

                      {formId && formData && !is_published ? (
                        <div
                          className="text-right"
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <img
                            src={addNew}
                            alt="Create New"
                            onClick={handleSecion}
                            title="Create New Section"
                            width="32px"
                            style={{
                              backgroundColor: "rgb(112, 112, 112) !important",
                              padding: "4px",
                              cursor: "pointer",
                              filter: "brightness(0.8)",
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(formQuestions).length > 0 ? (
        <>
          {" "}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {/* {JSON.stringify(activeSectionName)} {JSON.stringify(questionSectionsList)} */}
                  <Accordion
                    preExpanded={
                      activeSectionName.length > 0
                        ? activeSectionName
                        : questionSectionsList
                    }
                    allowMultipleExpanded={true}
                    onChange={handleCollapse}
                  >
                    {sectionsOrder.map((sectionObj, index) => (
                      <>
                        <Draggable
                          key={`${sectionObj}-${index}`}
                          draggableId={`${sectionObj}`}
                          // draggableId={`${sectionObj}-${index}`}
                          index={index}
                          isDragDisabled={redirectFrom !== FORM_BUILDER}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <AccordionItem
                                key={index}
                                style={{ borderBottom: "1px solid #c5c5c5" }}
                                uuid={sectionObj.split(" ").join("")}
                                // dangerouslySetExpanded={true}
                              >
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <span className="row section-adjustment">
                                      <span className="col-10 section-name-details">
                                        <span
                                          style={
                                            redirectFrom === FORM_BUILDER
                                              ? { fontSize: "20px" }
                                              : {
                                                  fontSize: "20px",
                                                  "padding-bottom": "10px",
                                                }
                                          }
                                        >
                                          {" "}
                                          <b>{sectionObj}</b>{" "}
                                        </span>
                                      </span>

                                      { ( [EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1 ? false : is_published )  ? null : (
                                        <>
                                          <span
                                            className="col-2"
                                            style={{ top: "-5px" }}
                                          >
                                            {/* <span> */}
                                            {[FORM_BUILDER,EDIT_FORM,EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1  ? (
                                              <>
                                                <span className="row">
                                                  {formQuestions[
                                                    `${sectionObj}`
                                                  ] &&
                                                  formQuestions[`${sectionObj}`]
                                                    .length === 0 &&
                                                  editableSectionsList.includes(
                                                    `${sectionObj}`
                                                  ) ? (
                                                    <>
                                                      <span className="col-2"></span>
                                                      <span className="col-2"></span>
                                                      <span className="col-2">
                                                        <i
                                                          class="fa fa-pencil app-icons"
                                                          aria-hidden="true"
                                                          title="Edit"
                                                          style={{
                                                            paddingRight:
                                                              "10px !important",
                                                          }}
                                                          onClick={(e) => {
                                                            editClick(
                                                              e,
                                                              sectionObj
                                                            );
                                                          }}
                                                        ></i>
                                                      </span>
                                                    </>
                                                  ) : null}
                                                  {formQuestions[
                                                    `${sectionObj}`
                                                  ] &&
                                                  formQuestions[`${sectionObj}`]
                                                    .length === 0 ? (
                                                    <>
                                                      {editableSectionsList.includes(
                                                        `${sectionObj}`
                                                      ) ? null : (
                                                        <>
                                                          <span className="col-2"></span>
                                                          <span className="col-2"></span>
                                                          <span className="col-2"></span>
                                                        </>
                                                      )}
                                                      <span className="col-2">
                                                        {" "}
                                                        <i
                                                          class="fa fa-trash app-icons"
                                                          aria-hidden="true"
                                                          title="Delete"
                                                          style={{
                                                            paddingRight:
                                                              "10px !important",
                                                          }}
                                                          onClick={(e) => {
                                                            deleteClick(
                                                              e,
                                                              sectionObj
                                                            );
                                                          }}
                                                        ></i>
                                                      </span>
                                                    </>
                                                  ) : null}

                                                  {formQuestions[
                                                    `${sectionObj}`
                                                  ] &&
                                                  formQuestions[`${sectionObj}`]
                                                    .length > 0 ? (
                                                    <>
                                                      <span className="col-8"></span>
                                                    </>
                                                  ) : null}
                                                  <span
                                                    className={`${
                                                      formQuestions[
                                                        `${sectionObj}`
                                                      ] &&
                                                      formQuestions[
                                                        `${sectionObj}`
                                                      ].length > 0
                                                        ? "col-4 pull-right min-section-padding"
                                                        : editableSectionsList.includes(
                                                            `${sectionObj}`
                                                          )
                                                        ? "col-4 pull-right min-section-padding"
                                                        : "col-4 pull-right min-section-padding"
                                                    }`}
                                                  >
                                                    <Dropdown
                                                      // onMouseLeave={() => setShowDropdown(false)}
                                                      // onMouseOver={() => setShowDropdown(true)}
                                                      // style={{ width: '166px' }}
                                                      onClick={onClickDropdown}
                                                    >
                                                      <Dropdown.Toggle
                                                        className="main-style"
                                                        id="dropdown-basic"
                                                      >
                                                        <img
                                                          src={addNew}
                                                          alt="Create New"
                                                          title="Add Question"
                                                          width="20px"
                                                          style={{
                                                            filter:
                                                              "brightness(0.8)",
                                                          }}
                                                        />{" "}
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu
                                                        show={showDropdown}
                                                      >
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleDropdownAction(
                                                              "createNew",
                                                              sectionObj
                                                            )
                                                          }
                                                        >
                                                          Create New Question
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleDropdownAction(
                                                              "AddFromLibrary",
                                                              sectionObj
                                                            )
                                                          }
                                                        >
                                                          Add From Library
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </span>
                                                </span>
                                              </>
                                            ) : null}
                                            {/* </span> */}
                                          </span>
                                        </>
                                      )}
                                    </span>

                                    {/* <span
                                      style={{
                                        textAlign: "right",
                                        display: "block",
                                      }}
                                    >
                                      
                                      
                                    </span> */}
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  {Object.keys(formQuestions).length > 0 ? (
                                    <>
                                      <Questions
                                        data={
                                          formQuestions[`${sectionObj}`]
                                            ? formQuestions[`${sectionObj}`]
                                            : []
                                        }
                                        delete={deleteAction}
                                        actions={
                                          // redirectFrom === FORM_BUILDER
                                          [FORM_BUILDER,EDIT_FORM,EDIT_PUBLISHED_FORM].indexOf(redirectFrom) !==-1
                                            ? localQuestionActions
                                            : []
                                        }
                                        // redirectFrom={FORM_BUILDER}
                                        sectionName={sectionObj}
                                        questionReorderAction={questionReorder}
                                        sendAnswers={sendAnswers}
                                        redirectFrom={redirectFrom}
                                        is_published={is_published}
                                        dragType={`${sectionObj}`}
                                        answersList={answersObj}
                                      />
                                    </>
                                  ) : null}
                                </AccordionItemPanel>
                              </AccordionItem>
                            </div>
                          )}
                        </Draggable>
                      </>
                    ))}
                  </Accordion>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="spacer"></div>
          {userDetails.role === SUPPLIER && (  [SUBMIT_ANSWERS,DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom) ) ? (
            <Button
              variant="btn app-default-button btn-sm btn-primary"
              type="button"
              onClick={()=>{
                draftAnswers(DRAFT)
              }}
            >
              Save
            </Button>
          ) : null} &nbsp;&nbsp;

          {userDetails.role === SUPPLIER && ( redirectFrom === SUBMIT_ANSWERS || [NEEDS_FOLLOWUP_ANSWERS,DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom) ) ? (
            <Button
              variant="btn app-default-button btn-sm btn-primary"
              type="button"
              onClick={submitAnswers}
            >
              Submit
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {sectionsList.map((sectionObj, index) => (
            <>
              <div className="card mb-4">
                <div
                  className="card-header"
                  style={{
                    backgroundColor: "#707070",
                  }}
                >
                  <div
                    className="row"
                    style={{ marginLeft: "0px", marginRight: "0px" }}
                  >
                    <div
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style={{ backgroundColor: "#dcdcdc", color: "#707070" }}
                    >
                      <div className="form-question-label">
                        <div className="row">
                          <div className="col-9">
                            <div className="row">
                              <div className="col-4">
                                <b>{sectionObj.value}</b>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="row">
                              <div className="col-2"></div>
                              <div className="col-4">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="create-new-btn btn-sm"
                                    id="dropdown-basic"
                                    // size="sm"
                                    title="Add new Question"
                                  >
                                    <img
                                      src={addNew}
                                      alt="Create New"
                                      style={{ filter: "brightness(0.8)" }}
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDropdownAction(
                                          "createNew",
                                          sectionObj.value
                                        )
                                      }
                                    >
                                      Create New Question
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDropdownAction(
                                          "AddFromLibrary",
                                          sectionObj.value
                                        )
                                      }
                                    >
                                      Add From Library
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="col-5">Question</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Object.keys(formQuestions).length > 0 ? (
                    <>
                      <Questions
                        data={formQuestions["it_security"]}
                        delete={deleteAction}
                        actions={localQuestionActions}
                        redirectFrom={FORM_BUILDER}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </>
          ))}
        </>
      )}
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      {/* </div> */}
    </div>
  );
};

export default CreateForm;
