import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PageLoader from "../../common/Loader";
import { useSelector } from "react-redux";
import { ERROR, ToastMessage } from "../../common/ToastMessage";

const SendForm = (props) => {
  const [show, setShow] = useState(props.isOpen);
  const [startDate, setStartDate] = useState(new Date());
  const [selected, setSelected] = useState([]);
  const { loader, formsList, adminFormsList , suppliersList } = useSelector(
    (state) => state.form
  );
  const validationSchema = Yup.object().shape({
    // isInvite:Yup.string(),
    isInvite: Yup.string().required("PayMode is required "),
    supplier_email:Yup.string().ensure()
    .when("isInvite", {
      is: true,
      then: Yup.string().required("Supplier email is required"),
    }),
    // supplier_ids: Yup.string().required("Supplier is required").nullable(),
    due_date: Yup.string().required("Due Date is required"),
    message: Yup.string().required("Message is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const isInviteWatch = watch("isInvite");
  useEffect(() => {
    reset();
    setSelected([])
    setShow(props.isOpen);
  }, [props.isOpen]);
  const handleClose = () => {
    reset();
    setShow(false);
    props.closeModal(false);
  };
  // const handleShow = () => {
  //   setShow(true);
  // };
  const onSubmit = async (data) => {
    if(!data.isInvite && selected.length === 0){
      ToastMessage(ERROR, "Please Select atleast one supplier")
    }if(data.isInvite && data.email === ''){
      ToastMessage(ERROR, "Please enter supplier email id")
    }else{
      let params = {
        ...data,supplier_ids:selected.map(obj=>obj.value)
      }
      if(data.isInvite){
        params.supplier_email = data.supplier_email;
      }else{
        params.supplier_email = null;
      }
      await props.send(params)
      reset();
    }
    
  };
  const error = async(data)=>[
    console.log(data)
  ]
  return (
    <>
      <Modal show={show} onHide={handleClose}>

      <Form
            noValidate
            // validated={validated}
            // onSubmit={e => submitForm(e)}
            onSubmit={handleSubmit(onSubmit,error)}
          >
        <Modal.Body>
        <PageLoader loader={loader}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Send Form to new supplier" {...register("isInvite")}/>
          </Form.Group>
          {isInviteWatch ? (
             <Form.Group controlId="formBasicPassword">
             <Form.Label>Supplier Email</Form.Label>
             <Form.Control
                 type="text"
                 placeholder="Enter supplier email"
                 aria-describedby="inputGroupPrepend"
                 {...register("supplier_email")}
                 className={`form-control ${errors.supplier_email ? "is-invalid" : ""}`}
                 style={{backgroundColor:"#e6e6e6"}}
               />
               <span className="invalid-feedback">
                 {errors.supplier_email?.message}
               </span>
           </Form.Group>
          ):null }
           {isInviteWatch ? null : (
            <Form.Group type="invalid" controlId="validationCustomUsername">
              <Form.Label>Send To</Form.Label>
              {/* <InputGroup> */}
                {/* <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  aria-describedby="inputGroupPrepend"
                  {...register("email")}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  style={{backgroundColor:"#e6e6e6"}}
                />
                <span className="invalid-feedback">
                  {errors.email?.message}
                </span> */}
                {/* <ReactMultiSelectCheckboxes options={suppliersList} /> */}
                <MultiSelect
                options={suppliersList}
                value={selected}
                onChange={setSelected}
                labelledBy="Select Suppliers list"
                disabled={isInviteWatch}
                // {...register("supplier_ids")}
              />
               <span className="invalid-feedback">
                  {errors.supplier_ids?.message}
                </span>
              {/* </InputGroup> */}
            </Form.Group>
           )}
            
            <Form.Group type="invalid" controlId="validationCustomUsername">
              <Form.Label>Due Date</Form.Label>
              
              <InputGroup>
                <Form.Control
                  type="date"
                  placeholder="select Date"
                  aria-describedby="inputGroupPrepend"
                  {...register("due_date")}
                  min={new Date().toISOString().split("T")[0]}
                  className={`form-control ${errors.due_date ? "is-invalid" : ""}`}
                  style={{backgroundColor:"#e6e6e6"}}
                />
                <span className="invalid-feedback">
                  {errors.due_date?.message}
                </span>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Custom Message</Form.Label>
              <InputGroup>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style={{backgroundColor:"#e6e6e6"}} name="message" {...register("message")} className={`form-control ${errors.message ? "is-invalid" : ""}`}></textarea>
                <span className="invalid-feedback">
                  {errors.message?.message}
                </span>
              </InputGroup>
            </Form.Group>
            {/* <div >
              <Button
                variant="btn app-default-button btn btn-primary"
                type="submit"
              >
                Send
              </Button>
            </div> */}
          </PageLoader>
        </Modal.Body>
        <Modal.Footer>
        <div className="row">

            <div className="col-4">

            </div>
            <div className="col-4">
            <Button
                  variant="btn app-default-button btn btn-primary"
                  type="submit"
                >
            Send
                  
                </Button>
                
            </div>
            <div className="col-4">
            <Button variant="secondary" onClick={handleClose}>
                              Cancel
                          </Button>
            </div>

            </div>   
        </Modal.Footer>
        </Form>

      </Modal>
    </>
  );
};

export default SendForm;
