// You can use CONSTANTS.js file for below definitions of constants and import here.
import callApi from "../../Api";
import {ToastMessage , SUCCESS, ERROR } from "../../common/ToastMessage";
import { push } from "react-router-redux";
import { APPROVED, CLIENT, EDIT_PUBLISHED_FORM } from "../../common/constants";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";

export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST";

export const GET_ADMIN_QUESTIONS_LIST = "GET_ADMIN_QUESTIONS_LIST";
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const ACTIVE_SECTION_NAME = "ACTIVE_SECTION_NAME"
export const RESET_ACTIVE_SECTION_NAME = "RESET_ACTIVE_SECTION_NAME";
export const QUESTION_TABS_LIST = "QUESTION_TABS_LIST";
export const RESET_QUESTION_TABS_LIST = "RESET_QUESTION_TABS_LIST"
export const UPDATE_FORM_STATUS = "UPDATE_FORM_STATUS"
export const IS_QUESTION_ADDED_TO_FORM = "IS_QUESTION_ADDED_TO_FORM"

export const openLoader = (data) => ({
  type: OPEN_LOADER,
  payload: true,
});
export const closeLoader = (data) => ({
  type: CLOSE_LOADER,
  payload: false,
});

export const createQuestion = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`forms/${reqObj.form_id}/add_question`,reqObj);
  if (data && data.data.success) {
    ToastMessage(SUCCESS,"Question created successfully.");
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};


export const getQuestionsList = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`questions`,{params:reqObj});
  if (data && data.data.success) {
    dispatch({ type: GET_QUESTIONS_LIST, payload: data.data.data });
    // ToastMessage(SUCCESS,data.messages.success);
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};

export const deleteQuestion = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.delete(`questions/${reqObj.id}`);
  if (data && data.data.success) {
    ToastMessage(SUCCESS,"Question deleted successfully.");
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};

export const addQuestionToForm = (reqObj) => async (dispatch) => {
  if(reqObj.form_questions_ids.length === 0){
    ToastMessage(ERROR,"Please select atleast one question");
  }else{
    dispatch(openLoader())
  const data = await callApi.post(`forms/${reqObj.form_id}/add_library_question_to_form`,reqObj);
  if (data && data.data.success) {
    dispatch({ type: ACTIVE_SECTION_NAME, payload: [reqObj.section.split(" ").join("")] });
    ToastMessage(SUCCESS,"Question added to the fom successfully.");
    if(reqObj.role === CLIENT){
      if(reqObj.vendor_id){
        dispatch(push(`/client/suppliers/${reqObj.vendor_id}/edit/${reqObj.form_id}`));
      }else if (reqObj[`form_type`] && reqObj[`form_type`] === EDIT_PUBLISHED_FORM){
        dispatch(push(`/client/formLibrary/formBuilder/editPublished/${reqObj.form_id}`));
      }else{
        dispatch(push(`/client/formLibrary/formBuilder/${reqObj.form_id}`));
      }
    }else{
      dispatch(push(`/admin/formLibrary/formBuilder/${reqObj.form_id}`));
    }
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
  }
  
};


export const getAdminQuestionsList = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`questions`,{params:reqObj});
  if (data && data.data.success) {
    const questionsList = data.data.data.filter(obj=>{
      if(obj.questions_options.length >0 ){
        obj.questions_options = obj.questions_options.filter(childObj=> {
          childObj.value = childObj.option_number;
          childObj.label = childObj.option_value;
          return childObj;
        })
      }
      return obj;
      
    })
    dispatch({ type: GET_ADMIN_QUESTIONS_LIST, payload: data.data.data });
    // ToastMessage(SUCCESS,data.messages.success);
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};


export const createGlobalQuestion = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`questions`,reqObj);
  if (data && data.data.success) {
    ToastMessage(SUCCESS,"Question created successfully.");
    // dispatch({ type: GET_ADMIN_QUESTIONS_LIST, payload: data.data.data });
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};

export const resetQuestionsList = (reqObj) => async (dispatch) => {
  dispatch({ type: GET_ADMIN_QUESTIONS_LIST, payload: [] });
  dispatch({ type: GET_QUESTIONS_LIST, payload: [] });
};

export const updateQuestionAction = (reqObj,question_id) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.put(`questions/${question_id}`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: UPDATE_QUESTION, payload: {
    //   id: reqObj.id,
    //   updateObj: data.data.data
    // } });
    ToastMessage(SUCCESS,"Question Updated Successfully");
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};
export const deleteFormQuestionLibraryAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.delete(`questions/${reqObj.id}`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: UPDATE_QUESTION, payload: {
    //   id: reqObj.id,
    //   updateObj: data.data.data
    // } });
    ToastMessage(SUCCESS,"Question Deleted Successfully");
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    // ToastMessage(ERROR,data.data.message);
  }
};

export const resetActiveSectionNameAction = (reqObj) => async (dispatch) => {
  dispatch({ type: RESET_ACTIVE_SECTION_NAME, payload: null });
};

export const approveFormAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`forms/update_form_status`,reqObj);
  if (data && data.data.success) {
    ToastMessage(SUCCESS,data.data.messages.success[0]);
    dispatch(closeLoader())
     const formStatusObj = {
      formStatus: reqObj.status,
      status_update_reasonses: data.data.data
      // status_update_reasonses: reqObj.status === APPROVED ? [] : data.data.data
    }
    dispatch({ type: UPDATE_FORM_STATUS, payload: formStatusObj });
    // dispatch(push(`/admin/suppliers/${reqObj.supplier_id}`));
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};
export const rejectFormAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`forms/update_form_status`,reqObj);
  if (data && data.data.success) {
    ToastMessage(SUCCESS,data.data.messages.success[0]);
    dispatch(closeLoader())
    dispatch(push(`/admin/suppliers/${reqObj.supplier_id}`));
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const createQuestionTabsList = (tabName) => async (dispatch) => {
  dispatch({ type: QUESTION_TABS_LIST, payload: tabName });
};
export const resetQuestionTabsList = (tabName) => async (dispatch) => {
  dispatch({ type: RESET_QUESTION_TABS_LIST, payload: [1] });
};

export const checkIsQuestionAdded = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`questions/match_question`,reqObj);
  if (data && data.data.success) {
    if(reqObj['reqFrom'] ==='AddButton' && !data.data.data.is_question_exist){
      dispatch(addQuestionToForm(reqObj.requestedParams))
    }else{
      dispatch({ type: IS_QUESTION_ADDED_TO_FORM, payload: data.data.data.is_question_exist });
    }
    // ToastMessage(SUCCESS,data.data.messages.success[0]);
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const resetIsQuestionAdded = (reqObj) => async (dispatch) => {
    dispatch({ type: IS_QUESTION_ADDED_TO_FORM, payload: false });
};
