import { Route, Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import {  CLIENT_SUPPLIERS,CLIENT_PENDING_FORMS,CLIENT_QUESTION_LIBRARY,CLIENT_FORM_LIBRARY,CLIENT_REPORTS,
  DASHBOARD,CLIENT_INVITE_SUPPLIER, CLIENT_FORM_BUILDER, CLIENT_COMPLAINT_PERCENTAGE, CLIENT_FORM_DUE_DATE,
  CLIENT_AUDIT_STATUS, CLIENT_RISK_SOCRE ,CLIENT_FORMS, ADMIN_FORM_BUILDER,ADMIN_FORM_LIBRARY_DETAILS, ADMIN_SUPPLIERS_DETAILS,SUPPLIER_CLIENTS ,SUPPLIER_CURRENT_CLIENTS,SUPPLIER_PENDING_FORMS,SUPPLIER_AUDIT_STATUS,SUPPLIER_FORMS ,SUPPLIER_REPORTS, ADMIN_CLIENTS, ADMIN_SUPPLIERS, ADMIN_FORM_LIBRARY, ADMIN_QUESTION_LIBRARY, PROFILE, CLIENT_FORM_LIBRARY_DETAILS, ADMIN_CLIENT_DETAILS, SUBMIT_FORM_ANSWERS, ADMIN_SUPPLIERS_ANSWERS_RESPONSE, ADMIN_SENT_FORMS, UPLOAD_FORMS, SUPPLIER_COMPLETED_FORMS, ADMIN_ADD_FROM_LIBRARY, CLIENT_COMPLETED_FORMS } from "../../routes/constants";
import { useSelector } from "react-redux";
import { ADMIN } from "../../common/constants";


const BreadCrumb = (props) => {
  const { clientView,userDetails } = useSelector(
    (state) => state.user
  );

  const routes = [
    { path: DASHBOARD, name: "Dashboard" },
    { path: PROFILE, name: "Edit Profile" },
    { path: CLIENT_SUPPLIERS, name: "Suppliers" },
    { path: "/client/suppliers/:id", name: "Supplier Details" },
    // { path: "/client/suppliers/searchSuppliers/list", name: "Search Supplier" },
    { path: "/client/suppliers/searchSuppliers/list/:id", name: "Supplier Details" },
    { path: CLIENT_FORM_LIBRARY, name: "Form Library" },
    { path: CLIENT_QUESTION_LIBRARY, name: "Question Library" },
    { path: CLIENT_INVITE_SUPPLIER, name: "Invite Supplier" },
    { path: CLIENT_FORM_BUILDER, name: "Form Builder" },
    { path: CLIENT_FORM_LIBRARY_DETAILS, name: "Form Details" },
    { path: CLIENT_REPORTS, name: "Reports" },
    { path: CLIENT_COMPLAINT_PERCENTAGE, name: "Complaint Percentage" },
    { path: CLIENT_FORM_DUE_DATE, name: "Form Due Date" },
    { path: CLIENT_AUDIT_STATUS, name: "Audit Status" },
    { path: CLIENT_FORMS, name: "Forms" },
    { path: CLIENT_RISK_SOCRE, name: "Risk Score" },
    { path: CLIENT_PENDING_FORMS, name: "Pending Forms" },
    
    { path: SUPPLIER_CLIENTS, name: "Clients" },
    { path: SUPPLIER_CURRENT_CLIENTS, name: "Current Clients" },
    { path: SUPPLIER_AUDIT_STATUS, name: "Audit Status" },
    { path: SUPPLIER_FORMS, name: "Forms" },
    { path: SUPPLIER_PENDING_FORMS, name: "Pending Forms" },
    { path: SUBMIT_FORM_ANSWERS, name: "Submit Answers" },
    { path: SUPPLIER_COMPLETED_FORMS, name: "Completed Forms" },
    { path: CLIENT_COMPLETED_FORMS, name: "Completed Forms" },
  
  
    { path: ADMIN_CLIENTS, name: "Clients" },
    { path: ADMIN_SUPPLIERS, name: "Suppliers" },
    { path: ADMIN_FORM_LIBRARY, name: "Form Library" },
    { path: ADMIN_QUESTION_LIBRARY, name: "Question Library" },
    { path: ADMIN_CLIENT_DETAILS, name: ( userDetails.role === ADMIN ? clientView[`name`] : "Client Details" ) },
    { path: ADMIN_SUPPLIERS_DETAILS, name: "Supplier Details" },
    { path: ADMIN_FORM_BUILDER, name: "Form Builder" },
    { path: ADMIN_FORM_LIBRARY_DETAILS, name: "Form Details" },
    { path: ADMIN_ADD_FROM_LIBRARY, name: "Question Library" },
    { path: ADMIN_SUPPLIERS_ANSWERS_RESPONSE, name: "Supplier Form Response" },
    { path: ADMIN_SENT_FORMS, name: "Sent Forms" },
    { path: UPLOAD_FORMS, name: "Upload Excel Forms" },
    { path: "/admin/clients/:client_id/suppliers", name:"Suppliers"  },
    { path: "/admin/clients/:client_id/pendingForms", name:"Pending Forms"  },
    { path: "/admin/clients/:client_id/completedForms", name:"Completed Forms"  },
    
    
  ];
  return (
    <>
      {routes.map(({ path, name }, key) => (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => {
            const crumbs = routes
              .filter(({ path }) => props.match.path.includes(path))
              .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                  ? Object.keys(props.match.params).reduce(
                      (path, param) =>
                        path.replace(`:${param}`, props.match.params[param]),
                      path
                    )
                  : path,
                ...rest,
              }));
            return (
              <>
              <div className="spacer"></div>
              <div className="row">
                <div className="col-12">
                  <Breadcrumb>
                    {/* <Breadcrumb.Item> */}
                    <Link to="/admin/clients"> Home</Link> &nbsp;/ &nbsp;
                    {/* </Breadcrumb.Item> */}
                    {crumbs.map(({ name, path }, key) =>
                      key + 1 === crumbs.length ? (
                        <Breadcrumb.Item active>{name} </Breadcrumb.Item>
                      ) : (
                        <>
                          <Breadcrumb.Item key={key} to={path}>
                            <Link to={path}> {name}</Link>
                          </Breadcrumb.Item>
                        </>
                      )
                    )}
                  </Breadcrumb>
                </div>
              </div>
            </>
            );
          }}
        />
      ))}
      {/* <hr /> */}
    </>
  );
};

export default BreadCrumb;
