import React from 'react';
import { MaterialReactTable } from 'material-react-table';

const GroupTable = (props) => {
  return (
    <>
    {props.columns.length ? (
      <>
      <MaterialReactTable
      columns={props.columns}
      data={props.rowData}
      enableColumnResizing
      enableGrouping
      // enableStickyHeader
      // enableStickyFooter
      initialState={{
        density: 'compact',
        expanded: (props.defaultExpand || false), //expand all groups by default
        grouping: (props.group_name || ['sent_to'] ), //an array of columns to group by by default (can be multiple)
        // pagination: { pageIndex: 0, pageSize: 20 },
        // sorting: [{ id: 'state', desc: false }], //sort by state by default
      }}
      // muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      muiTableContainerProps={{ sx: { maxHeight: 700 } }}
    />
      </>
    ): null }
    </>
  );
};

export default GroupTable;
