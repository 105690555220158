import React ,{useState} from 'react'

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

export const SearchBy = (props) => {
    const [searchBy, setSearchBy] = useState(null);

    const handleChange = (e)=>{
        const { name, value } = e.target;
        console.log("Nmae ",name,value)
        setSearchBy(value)
      }
    const hanldeSearchByText = ()=>{
        if(searchBy){
            props.hanldeSearchByText(searchBy)
        }
    }
  return (
    <>
        <InputGroup>
                  
                  <FormControl name="searchBy" id="inlineFormInputGroupUsername" placeholder="search..." onChange={handleChange}/>
                  <InputGroup.Prepend style={{cursor:"pointer"}} onClick={hanldeSearchByText}>
                    <InputGroup.Text><i class="fa fa-search"  ></i></InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
    </>
  )
}
