import React from "react";
import { Switch, Route ,Redirect  } from "react-router-dom";
import Suppliers from "../pages/Suppliers";
import FormLibrary from "../pages/FormLibrary";
import FormBuilder from "../pages/FormBuilder";
import QuestionLibrary from "../pages/QuestionLibrary";
import { ROOT,DASHBOARD,ADMIN_FORM_BUILDER, ADMIN_CLIENTS, ADMIN_SUPPLIERS, ADMIN_QUESTION_LIBRARY,ADMIN_FORM_LIBRARY,ADMIN_FORM_LIBRARY_DETAILS,
  ADMIN_CLIENT_DETAILS, ADMIN_CLIENT_SUPPLIERS, ADMIN_SUPPLIERS_DETAILS ,ADMIN_SUPPLIERS_ANSWERS_RESPONSE ,ADMIN_SENT_FORMS, UPLOAD_FORMS, ADMIN_ADD_FROM_LIBRARY ,CLIENT_SUPPLIERS ,CLIENT_FORM_LIBRARY,CLIENT_QUESTION_LIBRARY,CLIENT_PENDING_FORMS,CLIENT_COMPLETED_FORMS, CLIENT_REJETED_FORMS, SUBMIT_FORM_ANSWERS, CLIENT_SUBMITTED_FORMS,CLIENT_OPENEND_FORMS,CLIENT_REJECTED_FORMS} from "./constants";
import Dashboard from "../components/Dashboard";
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import Clients from "../pages/Clients";
import ClientDetails from "../pages/Clients/ClientDetails";
import BasicData from "../pages/Suppliers/BasicData";
import AdminFormResponse from "../pages/Suppliers/adminFormResponse";
import PendingForms from "../pages/PendingForms";
import UploadForms from "../pages/FormLibrary/uploadFile";
import CompletedForms from "../pages/CompletedForms";
import SubmitAnswers from "../pages/Suppliers/submitAnswers";
import SubmittedForms from "../pages/RejectedForms/submittedForms";
import OpenedForms from "../pages/RejectedForms/OpenedForms";
import RejectedForms from "../pages/RejectedForms";
export const AdminRouterConfig = (props) => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} >
             <Redirect to={ADMIN_CLIENTS} />
        </Route>
        {/* <Route exact path={DASHBOARD} component={Clients} /> */}
        {/* Admin Routes Start */}
        <Route exact path={ADMIN_CLIENTS} component={Clients} />
        {/* <Route exact path={ADMIN_SUPPLIERS} component={Suppliers} /> */}
        {/* <Route exact path={ADMIN_QUESTION_LIBRARY} component={QuestionLibrary} /> */}
        {/* <Route exact path={ADMIN_FORM_LIBRARY} component={FormLibrary} /> */}
        {/* <Route exact path={ADMIN_FORM_BUILDER} component={FormBuilder} /> */}
        {/* <Route exact path={ADMIN_FORM_LIBRARY_DETAILS} component={FormBuilder} /> */}
        {/* <Route exact path={ADMIN_SENT_FORMS} component={PendingForms} /> */}
        <Route exact path={ADMIN_CLIENT_DETAILS} component={ClientDetails} />
        <Route exact path={ADMIN_SUPPLIERS_DETAILS} component={BasicData} />
        <Route exact path={ADMIN_SUPPLIERS_ANSWERS_RESPONSE} component={AdminFormResponse} />
        <Route exact path={UPLOAD_FORMS} component={UploadForms} />
        {/* <Route exact path={ADMIN_ADD_FROM_LIBRARY} component={QuestionLibrary} /> */}


        <Route exact path={`/admin/clients/:client_id/suppliers`} component={Suppliers} />
        <Route exact path={`/admin/:client_id${CLIENT_FORM_LIBRARY}`} component={FormLibrary} />
        <Route exact path={`/admin/:client_id${CLIENT_QUESTION_LIBRARY}`} component={QuestionLibrary} />
        <Route exact path={`/admin/clients/:client_id/pendingForms`} component={PendingForms} />
        <Route exact path={`/admin/clients/:client_id/completedForms`}  component={CompletedForms} />

        <Route exact path={SUBMIT_FORM_ANSWERS} component={SubmitAnswers} />
        <Route exact path={`/admin/clients/:client_id/submittedForms`} component={SubmittedForms} />
        <Route exact path={`/admin/clients/:client_id/openedForms`}  component={OpenedForms} />
        <Route exact path={`/admin/clients/:client_id/rejectedForms`} component={RejectedForms} />
        
        <Route exact path="*">
          <Redirect to={ADMIN_CLIENTS} />
        </Route>
      </Switch>
    </div>
  );
};
