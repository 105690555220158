import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Form, Image } from "react-bootstrap";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  //   width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  //   overflow: 'hidden'
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const CustomFileUpload = (props) => {
  const { uploadFile, isPreviewRequired, uploadAction, fileData } = props;
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (Object.keys(fileData).length === 0) {
      setFiles([]);
    }
  }, [fileData]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: (props.fileType || 'image/*'),
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        uploadFile(acceptedFiles[0]);
      } else {
        alert("Please select only one file");
      }
    },
  });

  const removeImages = () => {
    setFiles([]);
    uploadFile({});
  };
  const thumbs = files.map((file, index) => (
    <>
    {/* <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <i className="fa fa-close" onClick={removeImages}></i>
        {file.name}
      </div>
    </div> */}
    <div  key={file.name}>
      <div style={thumbInner}>
      <span title={file.name}>{file.name.substr(0,10)}.{file.name.split('.').pop()}</span>
        <i className="fa fa-close" onClick={removeImages} style={{cursor:"pointer"}}></i>
        {/* <Image src={file.preview} style={img} roundedCircle /> */}
      </div>
    </div>
    </>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      {/* <section className="file-upload-container"> */}
      {isPreviewRequired ? (
        <div className="row">
          <div className="col-2"></div>
          <div className="col-2">
            {/* <Form.Label>{( props.fileTitle || "Upload File" )} </Form.Label> */}
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <i
                className={"fa fa-upload"}
                aria-hidden="true"
                title={"Upload"}
                style={{
                  fontSize: "20px",
                  paddingTop: "7px",
                  cursor: "pointer",
                }}
              ></i>
              {/* <p>Drag 'n' drop file here, or click to select files</p> */}
            </div>

          </div>
          <div className="col-8">
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div {...getRootProps({ className: "" })}>
              <input {...getInputProps()} />
              <p>Edit Profile</p>
            </div>
            {files.length > 0 ? (
              <Button
                onClick={uploadAction}
                className="btn  app-default-button btn btn-primary"
              >
                Upload
              </Button>
            ) : null}
          </div>
          <div className="col-4">
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
        </div>
      )}

      {/* </section> */}
    </>
  );
};
export default CustomFileUpload;
