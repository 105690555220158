// You can use CONSTANTS.js file for below definitions of constants and import here.
import callApi from "../../Api";
import {ToastMessage , ERROR, SUCCESS } from "../../common/ToastMessage";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";

export const GET_SUPPLIERS_LIST = "GET_SUPPLIERS_LIST";
export const GET_SUPPLIER_DETAILS = "GET_SUPPLIER_DETAILS";
export const GET_VERTICLE_LIST = "GET_VERTICLE_LIST";

// Admin list
export const GET_ADMIN_SUPPLIERS_LIST = "GET_ADMIN_SUPPLIERS_LIST";
export const GET_ADMIN_CLIENTS_LIST ="GET_ADMIN_CLIENTS_LIST"
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS"
export const SUPPLIER_PENDING_FORMS_LIST = "SUPPLIER_PENDING_FORMS_LIST"
export const GET_PUBLISHED_FORMS = "GET_PUBLISHED_FORMS"
export const SUPPLIER_SENT_FORMS = "SUPPLIER_SENT_FORMS";
export const RESET_PAGINATION = "RESET_PAGINATION";

export const SET_IS_CLIENT_VIEW = "SET_IS_CLIENT_VIEW";

export const updateSuppliersList = (data) => ({
  type: GET_SUPPLIERS_LIST,
  payload: data,
});
export const updateAdminSuppliersList = (data) => ({
  type: GET_ADMIN_SUPPLIERS_LIST,
  payload: data,
});
export const updateSupplierDetailsAction = (data) => ({
  type: GET_SUPPLIER_DETAILS,
  payload: data,
});
export const openLoader = (data) => ({
  type: OPEN_LOADER,
  payload: true,
});
export const closeLoader = (data) => ({
  type: CLOSE_LOADER,
  payload: false,
});

export const adminClientsListActionUpdate = (data) => ({
  type: GET_ADMIN_CLIENTS_LIST,
  payload: data,
});

export const getSuppliersList = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`clients/${reqObj.id}/suppliers`,{params:reqObj});
  if (data && data.data.success) {
    dispatch(updateSuppliersList(data.data.data));
    dispatch(closeLoader())
  } else {
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};

export const getClientSuppliersList = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`clients/${reqObj.client_id}/suppliers`,{params:reqObj});
  if (data && data.data.success) {
    dispatch(updateSuppliersList(data.data.data));
    dispatch(closeLoader())
  } else {
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};

export const getSupplierDetails = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`suppliers/${reqObj.id}`);
  if (data && data.data.success) {
      const newData = data.data.data.vendors_forms.filter(obj=>{
         obj.isNavigationRequired = obj.status === "Not Opened"
         return obj;
      })
    dispatch(updateSupplierDetailsAction(data.data.data));
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload:{
      data:newData,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      per_page: reqObj.per_page
    }  });
    dispatch(closeLoader())
  }else{
    ToastMessage(ERROR,data.data.message);
  }
};

export const getVerticlesData = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`verticals`);
  if (data && data.data.success) {
    dispatch({ type: GET_VERTICLE_LIST, payload: data.data.data });
    dispatch(closeLoader())
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};

export const deleteSupplier = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.delete(`suppliers/${reqObj.id}`);
  if (data && data.data.success) {
    dispatch({ type: GET_VERTICLE_LIST, payload: data.data.data });
    dispatch(closeLoader())
    ToastMessage(SUCCESS,"Supplier record deleted successfully");    
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};


// Admin Actions
export const getAdminSuppliersList = async(reqObj)=>{
  const data = await callApi.get(`suppliers`,{params:reqObj});
  return data;
}

export const getAdminSuppliersListAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await getAdminSuppliersList(reqObj)
  if (data && data.data.success) {
    dispatch(updateAdminSuppliersList({
      data: data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      per_page: reqObj.per_page
    }));
    dispatch(closeLoader())
  } else {
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};
export const getAdminClientsList = async(reqObj)=>{
  const data = await callApi.get(`clients`,{params:reqObj});
  return data;
}
export const getAdminClients = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await getAdminClientsList(reqObj);
  if (data && data.data.success) {
    dispatch(adminClientsListActionUpdate({
      data:data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    }));
    dispatch(closeLoader())
  } else {
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};


export const getClientDetails = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`clients/${reqObj.id}`);
  if (data && data.data.success) {
    
    dispatch({ type: GET_CLIENT_DETAILS, payload: data.data.data });
    dispatch(closeLoader())
  }else{
    ToastMessage(ERROR,data.data.message);
  }
};
export const supplierPendingForms = async(reqObj)=>{
  const data = await callApi.get(`suppliers/${reqObj.supplier_id}/get_pending_forms`,{params : reqObj});
  return data;
}
export const supplierPendingFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await supplierPendingForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const updateSupplierAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.put(`suppliers/${reqObj.id}`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: data.data.data.vendors_forms });
    dispatch(closeLoader())
    ToastMessage(SUCCESS, data.data.messages.success[0]);
  }else{
    ToastMessage(ERROR,data.data.message);
  }
};

export const updateClientProfileAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.put(`clients/${reqObj.id}`,reqObj);
  if (data && data.data.success) {
    
    dispatch({ type: GET_CLIENT_DETAILS, payload: data.data.data });
    dispatch(closeLoader())
    ToastMessage(SUCCESS, data.data.messages.success[0]);
  }else{
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const getPublishedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`forms/get_published_forms`,{params:reqObj});
  if (data && data.data.success) {
    
    dispatch({ type: GET_PUBLISHED_FORMS, payload: data.data.data });
    dispatch(closeLoader())
    ToastMessage(SUCCESS, data.data.messages.success[0]);
  }else{
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};
export const sendFormToSupplierAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`suppliers/${reqObj.supplier_id}/send_forms_to_supplier`,reqObj);
  if (data && data.data.success) {
    
    dispatch(closeLoader())
    ToastMessage(SUCCESS, data.data.messages.success[0]);
  }else{
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const sentFormsList = async(reqObj)=>{
  const data = await callApi.get(`forms/sent_forms`,{params:reqObj});
  return data;
}

export const sentFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await sentFormsList(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      // total_items: data.data.pagination.total_count,
      page: reqObj.page,
      // total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};


export const resetPaginationAction = (data) => ({
  type: RESET_PAGINATION,
  payload: {},
});

export const clientDeleteAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.delete(`clients/${reqObj.id}`);
  if (data && data.data.success) {
    dispatch(closeLoader())
    ToastMessage(SUCCESS,data.data.messages.success[0]);    
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};
export const supplierCompletedForms = async(reqObj)=>{
  const data = await callApi.get(`suppliers/${reqObj.supplier_id}/get_completed_forms`,{params : reqObj});
  return data;
}
export const supplierCompletedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await supplierCompletedForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const clientPendingForms = async(reqObj)=>{
  const data = await callApi.get(`clients/client_pending_forms`,{params : reqObj});
  return data;
}

export const clientPendingFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await clientPendingForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const deleteFormsFromSuppliers = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.post(`suppliers/delete_supplier_form`, reqObj);
  if (data && data.data.success) {
    dispatch(closeLoader())
    ToastMessage(SUCCESS,"Supplier Form deleted successfully");    
  }else{
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};
export const clientCompletedForms = async(reqObj)=>{
  const data = await callApi.get(`clients/client_completed_forms`,{params : reqObj});
  return data;
}

export const clientRejectedForms = async(reqObj)=>{
  const data = await callApi.get(`clients/client_rejected_forms`,{params : reqObj});
  return data;
}

export const clientCompletedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await clientCompletedForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const clientRejectedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await clientRejectedForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};


export const setClientViewAction = (reqObj) => async (dispatch) => {
  dispatch({
    type: SET_IS_CLIENT_VIEW,
    payload: reqObj,
  });
};


export const getAllSuppliersList = (reqObj) => async (dispatch) => {
  dispatch(openLoader())
  const data = await callApi.get(`suppliers`,{params:reqObj});
  if (data && data.data.success) {
    dispatch(updateSuppliersList(data.data.data));
    dispatch(closeLoader())
  } else {
    dispatch(closeLoader())
    ToastMessage(ERROR,data.data.message);
  }
};


export const clientSubmittedForms = async(reqObj)=>{
  const data = await callApi.get(`clients/vendor_submitted_forms`,{params : reqObj});
  return data;
}

export const clientSubmittedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await clientSubmittedForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};


export const clientOpenedForms = async(reqObj)=>{
  const data = await callApi.get(`clients/vendor_opened_forms`,{params : reqObj});
  return data;
}

export const clientOpenedFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await clientOpenedForms(reqObj);
  if (data && data.data.success) {
    dispatch({ type: SUPPLIER_PENDING_FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};