// UnAuthorized Routes
export const ROOT = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signUp";
export const FORGOT_PASSWORD = "/forgotPassword"
export const RESET_PASSWORD = "/resetPassword"

// Dashboard
export const DASHBOARD = "/dashboard";
export const PROFILE = "/profile";

// Client Routes 
export const CLIENT_SUPPLIERS = "/client/suppliers";
export const CLIENT_PENDING_FORMS = "/client/pendingForms";
export const CLIENT_QUESTION_LIBRARY = "/client/questionLibrary";
export const CLIENT_REPORTS = "/client/reports";
export const CLIENT_FORM_LIBRARY = "/client/formLibrary";
export const CLIENT_FORM_BUILDER = "/client/formLibrary/formBuilder";
export const CLIENT_INVITE_SUPPLIER = "/client/inviteSupplier";
export const CLIENT_COMPLAINT_PERCENTAGE = "/client/reports/complaintPercentage";
export const CLIENT_FORM_DUE_DATE = "/client/reports/formDueDate";
export const CLIENT_AUDIT_STATUS = "/client/reports/auditStatus";
export const CLIENT_FORMS = "/client/reports/forms";
export const CLIENT_RISK_SOCRE = "/client/reports/riskScore";
export const CLIENT_AUDIT_STATUS_BY_OWNER = "/client/reports/auditStatusOwner";
export const CLIENT_FORM_LIBRARY_DETAILS = "/client/formLibrary/formBuilder/:id";
export const CLIENT_SUBMIT_FORM_ANSWERS = "/supplier/pendingForms/submitAnswers/:id";
export const CLIENT_COMPLETED_FORMS = "/client/approvedForms";
export const CLIENT_REJECTED_FORMS = "/client/rejectedForms";
export const CLIENT_SUPPLIERS_EDIT_FORM = "/client/suppliers/:supplier_id/edit/:id";
export const CLIENT_SUPPLIER_ADD_FROM_LIBRARY = "/client/suplier/:supplier_id/formLibrary/formBuilder/:id/questionLibrary/:section_name";
export const CLIENT_EDIT_PUBLISHED_FORM_LIBRARY_DETAILS = "/client/formLibrary/formBuilder/editPublished/:id";
export const CLIENT_ADD_FROM_LIBRARY_TO_PUBLISHED_FORM = "/client/formLibrary/:form_type/formBuilder/:id/questionLibrary/:section_name";

export const CLIENT_SUBMITTED_FORMS = "/client/submittedForms";
export const CLIENT_OPENEND_FORMS = "/client/openedForms";


// Supplier Routes

export const SUPPLIER_CLIENTS = "/supplier/clients";
export const SUPPLIER_PENDING_FORMS = "/supplier/pendingForms";
export const SUBMIT_FORM_ANSWERS = "/supplier/pendingForms/submitAnswers/:id";
export const SUPPLIER_REPORTS = "/supplier/reports";
export const SUPPLIER_CURRENT_CLIENTS = "/supplier/reports/currentClients";
export const SUPPLIER_AUDIT_STATUS = "/supplier/reports/auditStatus";
export const SUPPLIER_FORMS = "/supplier/reports/forms";
export const SUPPLIER_COMPLETED_FORMS = "/supplier/completedForms";
export const SUBMIT_FORM_ANSWERS_NEEDS_FOLLOWUP = "/supplier/pendingForms/:id/submitAnswers/:supplier_id/followup";
export const SUBMIT_FORM_ANSWERS_DRAFT = "/supplier/pendingForms/:id/submitAnswers/:supplier_id/draft";
export const SUBMIT_FORM_ANSWERS_INPROGRESS = "/supplier/pendingForms/:id/submitAnswers/:supplier_id/inprogress";


// Admin Routes 
export const ADMIN_SUPPLIERS = "/admin/suppliers";
export const ADMIN_CLIENTS = "/admin/clients";
export const ADMIN_QUESTION_LIBRARY = "/admin/questionLibrary";
export const ADMIN_ADD_FROM_LIBRARY = "/admin/formLibrary/formBuilder/:id/questionLibrary/:section_name";
export const ADMIN_FORM_LIBRARY = "/admin/formLibrary";
export const ADMIN_FORM_BUILDER = "/admin/formLibrary/formBuilder";
export const ADMIN_FORM_LIBRARY_DETAILS = "/admin/formLibrary/formBuilder/:id";
export const ADMIN_CLIENT_DETAILS = "/admin/clients/:client_id";
export const ADMIN_SUPPLIERS_DETAILS = "/admin/suppliers/:id";
export const ADMIN_SUPPLIERS_ANSWERS_RESPONSE = "/admin/suppliers/:supplier_id/submitAnswers/:id";
export const ADMIN_SENT_FORMS = "/admin/sentForms";
export const UPLOAD_FORMS = "/admin/formLibrary/uploadForms";

