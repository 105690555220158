export const CLIENT = "Client";
export const SUPPLIER = "Supplier";
export const PARTNER = "Partner";
export const ADMIN = 'Admin';

export const CHOOSE_ONE_ONLY = "Choose single response";
export const CHOOSE_MORE_THAN_ONE = "Choose single response in a dropdown";
export const CONDITIONAL_QUESTIONS = "Conditional questions";
export const MULTIPLE_CHOICE_WITH = "Choose multiple responses";

export const FORM_BUILDER ="Form Builder";
export const SUBMIT_ANSWERS ="Submit Answers";
export const ADMIN_FORM_ANSWERS ="Admin Form Answers"
export const ADMIN_SENT_FORM_ANSWERS ="Admin Sent Form Answers"
export const NEEDS_FOLLOWUP_ANSWERS ="Needs Followup Answers"
export const DRAFT_ANSWERS ="Draft Answers"
export const IN_PROGRESS_ANSWERS ="In Progress Answers"
export const DRAFT ="Draft"
export const EDIT_FORM ="Edit Form"
export const EDIT_PUBLISHED_FORM ="Edit Published Forms";

export const SHORT_ANSWER =  "Short Answer";
export const LONG_ANSWER =  "Long Answer";
export const OPTIONS =  "Question Options";
export const QUESTION_NUMBER =  "Number";
export const STATEMENT =  "Statement";
export const YES_OR_NO =  "Yes/No/Other";
export const DATE_TYPE =  "Date";
export const FILE_TYPE =  "Attachment";

export const APPROVED = "Approved";
export const REJECTED = "Rejected"
export const SUBMITTED= "Submitted"

export const NOT_SUBMITTED = "Not Submitted";
export const IN_REVIEW = "In Review"
export const NEEDS_FOLLOWUP= "Needs Followup"
export const NOT_STARTED = "Not Open";
export const PUBLISHED = "Published"
export const UN_PUBLISHED = "Unpublished"
export const IN_PROGRESS = "In Progress"
export const NOT_OPENED = "Not Opened"

export const PER_PAGE = 20;
export const DEFAULT_PER_PAGE=100;

export const QUESTION_LIBRARY = "Question Library"
