import React, { useEffect, useState } from "react";
// import Forms from './Forms';
// import ManageUsers from './ManageUsers';
import {
  getSupplierDetails,
  supplierPendingFormsAction,
  updateSupplierAction,
} from "../../redux/actions/suppliersAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";

import BreadCrumb from "../../components/BreadCrumb";
import Layout from "../../components/Layout";
import Forms from "./Forms";
import { Button } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Footer from "../Footer";
import { ADMIN } from "../../common/constants";

const BasicData = (props) => {
  const { pathname } = useLocation();
  const isFormEnable = pathname.includes("/suppliers/searchSuppliers/list");
  const dispatch = useDispatch();
  const { supplierDetails, supplierPendingForms } = useSelector(
    (state) => state.supplier
  );
  const { userDetails } = useSelector((state) => state.user);
  const params = useParams();
  const [sideMenu, setSideMenu] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [supplierData, setSupplierData] = useState({});
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    no_of_employees: Yup.string().required("no_of_employees is required"),
    // organization_name: Yup.string().required("Organization name is required").nullable(),
    primary_vertical: Yup.string().nullable(),
    revenue: Yup.string().nullable(),
    secondary_vertical: Yup.string().nullable(),
    tax_id: Yup.string().required("Tax Id is required"),
    // address:Yup.object().shape({
    address_line1: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string().required("ZipCode is required"),
    secondary_email: Yup.string().nullable(),
    secondary_notes: Yup.string().nullable(),
    secondary_phone: Yup.string().nullable(),
    primary_email: Yup.string().nullable(),
    primary_notes: Yup.string().nullable(),
    primary_phone: Yup.string().nullable(),

    company_name: Yup.string().nullable(),
    sic_code: Yup.string().nullable(),
    created_at: Yup.string().nullable(),
    locations: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    bank_information: Yup.string().nullable(),
    years_in_business: Yup.string().nullable(),
    number_of_clients: Yup.string().nullable(),
    primary_services: Yup.string().nullable(),
    // })
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...supplierDetails,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    async function fetchSupplierDetails() {
      await dispatch(getSupplierDetails({ id: params.id }));
      // await dispatch(supplierPendingFormsAction({
      //     supplier_id: params.id
      // }))
    }
    fetchSupplierDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    //   setSupplierData(supplierDetails);
    if (Object.keys(supplierDetails).length > 0) {
      setValue("name", supplierDetails.name);
      setValue("email", supplierDetails.email);
      setValue("no_of_employees", supplierDetails.no_of_employees);
      // setValue("organization_name", supplierDetails.organization_name);
      setValue("primary_vertical", supplierDetails.primary_vertical);
      setValue("revenue", supplierDetails.revenue);
      setValue("secondary_vertical", supplierDetails.secondary_vertical);
      setValue("tax_id", supplierDetails.tax_id);
      setValue("address_line1", supplierDetails.address.address_line1);
      setValue("country", supplierDetails.address.country);
      setValue("state", supplierDetails.address.state);
      setValue("zip_code", supplierDetails.address.zip_code);
      setValue("primary_email", (supplierDetails.contact ? supplierDetails.contact.primary_email : null ) );
      setValue("primary_phone", (supplierDetails.contact ? supplierDetails.contact.primary_phone : null ) );
      setValue("primary_notes", (supplierDetails.contact ? supplierDetails.contact.primary_notes : null ) );
      setValue("secondary_email", (supplierDetails.contact ? supplierDetails.contact.secondary_email : null ) );
      setValue("secondary_phone", (supplierDetails.contact ? supplierDetails.contact.secondary_phone : null ) );
      setValue("secondary_notes", (supplierDetails.contact ? supplierDetails.contact.secondary_notes : null ) );

      setValue("company_name",supplierDetails.company_name);
      setValue("sic_code",supplierDetails.sic_code);
      setValue("created_at",supplierDetails.created_at);
      setValue("locations",supplierDetails.locations);
      setValue("notes",supplierDetails.notes);
      setValue("bank_information",supplierDetails.bank_information);
      setValue("years_in_business",supplierDetails.years_in_business);
      setValue("number_of_clients",supplierDetails.number_of_clients);
      setValue("primary_services",supplierDetails.primary_services);

    }
  }, [supplierDetails]);

  const handleEdit = () => {
    setIsDisable(false);
  };
  const onSubmit = async (data) => {
    await dispatch(
      updateSupplierAction({ ...data, ...data.address, id: params.id })
    );
    setIsDisable(true)
  };
  const onError = (data) => [console.log(data)];
  var editButton;
  if(userDetails.role !== ADMIN){
    editButton = <Button
    className="btn app-default-button btn-sm btn-primary"
    onClick={handleEdit}
  >
    Edit Profile
  </Button>
  }
  return (
    <>
      <div className="page-wrapper">
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="row">
            <div className="col-10"></div>
            <div className="col-2 text-right">
            {editButton}
            </div>
          </div>
          <div className="spacer"></div>

          <form
            noValidate
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div class="card">
              <div class="card-header card-background-color">Supplier Profile</div>
              <div class="card-body card-body-background-color ">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Supplier Name
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.name ? "is-invalid" : ""
                          } ` }
                          id="staticEmail"
                          {...register("name")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.name?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Tax Id
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.tax_id ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("tax_id")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.tax_id?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Email
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.email ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("email")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.email?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Primary Vertical
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.primary_vertical ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("primary_vertical")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.primary_vertical?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Secondary Vertical
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.secondary_vertical ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("secondary_vertical")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.secondary_vertical?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        No Of Employees
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.no_of_employees ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("no_of_employees")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.no_of_employees?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>

                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Company Name
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.company_name ? "is-invalid" : ""
                          } ` }
                          id="staticEmail"
                          {...register("company_name")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.company_name?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Primary Services
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.primary_services ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("primary_services")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.primary_services?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Locations
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.locations ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("locations")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.locations?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Bank information
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.bank_information ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("bank_information")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.bank_information?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    
                  </div>
                  <div className="col-2 d-none d-lg-block d-xl-block"></div>
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Revenue
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.revenue ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("revenue")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                          {errors.revenue?.message}
                        </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Address
                      </label>
                      <div class="col-sm-8">
                        <textarea
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.address_line1 ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("address_line1")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.address_line1?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Country
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.country ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("country")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.country?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        State
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.state ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("state")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.state?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Postal Code
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.zip_code ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("zip_code")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.zip_code?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      SIC Code
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.sic_code ? "is-invalid" : ""
                          } ` }
                          id="staticEmail"
                          {...register("sic_code")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.sic_code?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Date Record Created
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.created_at ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("created_at")}
                          disabled={true}
                        />
                        <span className="invalid-feedback">
                        {errors.created_at?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Notes
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.notes ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("notes")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.notes?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Years in business
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.years_in_business ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("years_in_business")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.years_in_business?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                      Number of clients
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.number_of_clients ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("number_of_clients")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.number_of_clients?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    
                  </div>
                  {/* <div className="col-1"></div> */}
                </div>

                <div style={{fontSize:"18px"}}><b>Contact Details</b></div>
                <hr/>
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                   
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Primary Email
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.primary_email ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("primary_email")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.primary_email?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Primary Phone Number
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.primary_phone ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("primary_phone")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.primary_phone?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Primary Notes
                      </label>
                      <div class="col-sm-8">
                        <textarea
                          rows="3"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.primary_notes ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("primary_notes")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.primary_notes?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                  </div>
                  <div className="col-2 d-none d-lg-block d-xl-block"></div>
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Secondary Email
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.secondary_email ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("secondary_email")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.secondary_email?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Secondary Phone Number
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.secondary_phone ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("secondary_phone")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.secondary_phone?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Secondary Notes
                      </label>
                      <div class="col-sm-8">
                        <textarea
                          type="textarea"
                          rows="3"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.secondary_notes ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("secondary_notes")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.secondary_notes?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                  </div>
                  {/* <div className="col-1"></div> */}
                </div>
              </div>
            </div>
            <div className="spacer"></div>
            {isDisable ? null : (
              <>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 text-right">
                    <Button
                      variant="btn app-default-button btn btn-primary"
                      type="submit"
                      // onClick={handleClose}
                    >
                      Update Profile
                    </Button>
                  </div>
                </div>
              </>
            )}
          </form>

          <div className="spacer"></div>
          <Forms isFormEnable={isFormEnable} data={supplierPendingForms} />
        </div>
        <div className="flex-grow-1"></div>
                    <Footer /> 
      </div>

      {/* <ManageUsers /> */}
    </>
  );
};

export default BasicData;
