import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import {
  ADMIN,
  APPROVED,
  CLIENT,
  DRAFT,
  DRAFT_ANSWERS,
  IN_PROGRESS,
  NEEDS_FOLLOWUP,
  PER_PAGE,
  SUBMITTED,
  SUPPLIER,
} from "../../common/constants";
import {
  clientPendingFormsAction,
  resetPaginationAction,
  sentFormsAction,
  supplierCompletedFormsAction,
  supplierPendingFormsAction,
  SUPPLIER_PENDING_FORMS_LIST,
  SUPPLIER_SENT_FORMS,
} from "../../redux/actions/suppliersAction";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AgGridTable from "../../components/Tables/agGridTable";
import Footer from "../../pages/Footer";
import { SearchBy } from "../../common/SearchBy";
import GroupTable from "../../components/Tables/GroupTable";
const PendingForms = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams()
  const clientId = params.client_id
  const [sideMenu, setSideMenu] = useState(true);
  const [headerList, setHeaderList] = useState([]);
  const [agGridRef, setAgGridRef] = useState(null);
  const [rowNode, setRowNode] = useState(null);
  const { loader, supplierPendingForms, pagination } = useSelector(
    (state) => state.supplier
  );
  const { userDetails } = useSelector((state) => state.user);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const editAction = (data) => {};
  const AidRenderComponent = (props) => {
    return (
      <>
        {props.data ? (
          <NavLink
            to={`/admin/suppliers/${props.data.vendor_id}/submitAnswers/${props.data.form_id}`}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
            >
              {props.value}
            </span>{" "}
          </NavLink>
        ) : null}
      </>
    );
  };
  const supplierFormRender = (props) => {
    return (
      <>
      {props.data?.isGroupByRequired ? (
        <>
          {props.value}
        </>
      ):(
        <>
          {props.data ? (
          <NavLink
            to={
              props.data.vendor_form_status === NEEDS_FOLLOWUP
                ? `/supplier/pendingForms/${props.data.form_id}/submitAnswers/${props.data.vendor_id}/followup`
                : props.data.vendor_form_status === DRAFT
                ? `/supplier/pendingForms/${props.data.form_id}/submitAnswers/${props.data.vendor_id}/draft`
                : props.data.vendor_form_status === IN_PROGRESS ? `/supplier/pendingForms/${props.data.form_id}/submitAnswers/${props.data.vendor_id}/inprogress` : `/supplier/pendingForms/submitAnswers/${props.data.form_id}` 
            }
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
            >
              {props.value}{" "}
            </span>{" "}
          </NavLink>
        ) : null}
        </>
      )}
        
      </>
    );
  };
  const statusRender = (props) => {
    
    return (
      <>
        {props.data ? (
          <>
            {props.data.vendor_form_status === SUBMITTED ||
            props.data.vendor_form_status === APPROVED ? (
              <>
                {" "}
                <i
                  class="fa fa-circle"
                  aria-hidden="true"
                  style={{ color: "#318c2d", fontSize: "10px" }}
                ></i>{" "}
                &nbsp; {props.data.vendor_form_status}{" "}
              </>
            ) : (
              <>
                {props.data.vendor_form_status && (
                  <>
                    <i
                  class="fa fa-circle"
                  aria-hidden="true"
                  style={{ color: "#ff6060", fontSize: "10px" }}
                ></i>{" "}
                  &nbsp; {props.data.vendor_form_status}{" "}
                  </>
                )}
                
              </>
            )}
          </>
        ) : null}
      </>
    );
  };
  const sendToRenderComponent = (props) => {
    return (
      <>
        {" "}
        {props.data ? (
          <>
            {" "}
            <NavLink to={`/admin/suppliers/${props.data.vendor_id}`}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {props.value}
              </span>{" "}
            </NavLink>
          </>
        ) : null}
      </>
    );
  };

  useEffect(async () => {
    dispatch(resetPaginationAction());
    if (userDetails.role === SUPPLIER) {
      // setHeaderList([
      //   {
      //     headerName: "Form Name",
      //     field: "form_name",
      //     filter: true,
      //     sortable: true,
      //     floatingFilter: true,
      //     cellRendererFramework: supplierFormRender,
      //   },
      //   {
      //     headerName: "Sent To",
      //     field: "sent_to",
      //     filter: true,
      //     floatingFilter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Due Date",
      //     field: "due_date",
      //     floatingFilter: true,
      //     filter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Status",
      //     field: "status",
      //     filter: true,
      //     floatingFilter: true,
      //     sortable: true,
      //     cellRendererFramework: statusRender,
      //   },
      // ]);
      setHeaderList([
        {
          header: "Sent To",
          accessorKey: "sent_to",
          enableGrouping: true
        },
        {
          header: "Form Name",
          accessorKey: "form_name",
          Cell: ({ cell }) => (
            <><GroupSupplierFormRender {...cell}/> </>
          ),
        },
        {
          header: "Due Date",
          accessorKey: "due_date"
        },
        {
          header: "Completion",
          accessorKey: "completion"
        },
        {
          header: "Status",
          accessorKey: "vendor_form_status",
          Cell: ({ cell }) => (
            <><GroupStatusRender {...cell}/> </>
          ),
          
        }
      ])
      if (location.pathname === "/supplier/completedForms") {
        await dispatch(
          supplierCompletedFormsAction({
            supplier_id: userDetails.supplier_id,
            page: 1,
            per_page: PER_PAGE,
          })
        );
      } else {
        await dispatch(
          supplierPendingFormsAction({
            supplier_id: userDetails.supplier_id,
            page: 1,
            per_page: PER_PAGE,
          })
        );
      }
    }
    if (userDetails.role === ADMIN) {
      // setHeaderList([
      //   {
      //     headerName: "Form Name",
      //     field: "form_name",
      //     // filter: true,
      //     sortable: true,
      //     // floatingFilter: true,
      //     cellRendererFramework: AidRenderComponent,
      //   },
      //   {
      //     headerName: "Sent To",
      //     field: "sent_to",
      //     // filter: true,
      //     // floatingFilter: true,
      //     sortable: true,
      //     cellRendererFramework: sendToRenderComponent,
      //   },
      //   {
      //     headerName: "Due Date",
      //     field: "due_date",
      //     // filter: true,
      //     // floatingFilter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Status",
      //     field: "status",
      //     // filter: true,
      //     // floatingFilter: true,
      //     sortable: true,
      //     cellRendererFramework: statusRender,
      //   },
      // ]);
      setHeaderList([
        {
          header: "Sent To",
          accessorKey: "sent_to",
          enableGrouping: true
        },
        {
          header: "Form Name",
          accessorKey: "form_name",
          Cell: ({ cell }) => (
            <><GroupSupplierFormRender {...cell}/> </>
          ),
        },
        {
          header: "Due Date",
          accessorKey: "due_date"
        },
        {
          header: "Completion",
          accessorKey: "completion"
        },
        {
          header: "Status",
          accessorKey: "vendor_form_status",
          Cell: ({ cell }) => (
            <><GroupStatusRender {...cell}/> </>
          ),
          
        }
      ])
      // await dispatch(
      //   sentFormsAction({
      //     page: 1,
      //     per_page: PER_PAGE,
      //   })
      // );
      await dispatch(
        clientPendingFormsAction({
          client_id: clientId,
          page: 1,
          per_page: PER_PAGE,
        })
      );
    }
    if (userDetails.role === CLIENT) {
      // setHeaderList([
      //   {
      //     headerName: "Form Name",
      //     field: "form_name",
      //     // filter: true,
      //     sortable: true,
      //     // floatingFilter: true,
      //     cellRendererFramework: supplierFormRender,
      //   },
      //   {
      //     headerName: "Sent To",
      //     field: "sent_to",
      //     // filter: true,
      //     // floatingFilter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Due Date",
      //     field: "due_date",
      //     // floatingFilter: true,
      //     // filter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Completion",
      //     field: "completion",
      //     // floatingFilter: true,
      //     // filter: true,
      //     sortable: true,
      //   },
      //   {
      //     headerName: "Status",
      //     field: "status",
      //     // filter: true,
      //     // floatingFilter: true,
      //     sortable: true,
      //     cellRendererFramework: statusRender,
      //   },
      // ]);
      setHeaderList([
        {
          header: "Sent To",
          accessorKey: "sent_to",
          enableGrouping: true
        },
        {
          header: "Form Name",
          accessorKey: "form_name",
          Cell: ({ cell }) => (
            <><GroupSupplierFormRender {...cell}/> </>
          ),
        },
        {
          header: "Due Date",
          accessorKey: "due_date"
        },
        {
          header: "Completion",
          accessorKey: "completion"
        },
        {
          header: "Status",
          accessorKey: "vendor_form_status",
          Cell: ({ cell }) => (
            <><GroupStatusRender {...cell}/> </>
          ),
          
        }
      ])
      await dispatch(
        clientPendingFormsAction({
          client_id: userDetails.client_id,
          page: 1,
          per_page: PER_PAGE,
        })
      );
    }
  }, []);

  const setGridRefData = (params) => {
    setAgGridRef(params);
  };
  const fetchParamsList = () => {
    return {
      supplier_id: userDetails.supplier_id,
    };
  };

  const GroupStatusRender = (props) => {
    const { original } = props.row
  return (
    <>
      {original ? (
        <>
          {original.vendor_form_status === SUBMITTED ||
          original.vendor_form_status === APPROVED ? (
            <>
              {" "}
              <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ color: "#318c2d", fontSize: "10px" }}
              ></i>{" "}
              &nbsp; {original.vendor_form_status}{" "}
            </>
          ) : (
            <>
              {original.vendor_form_status && (
                <>
                  <i
                class="fa fa-circle"
                aria-hidden="true"
                style={{ color: "#ff6060", fontSize: "10px" }}
              ></i>{" "}
                &nbsp; {original.vendor_form_status}{" "}
                </>
              )}
              
            </>
          )}
        </>
      ) : null}
    </>
  );
};
const GroupSupplierFormRender = (props) => {
  const { original } = props.row
  return (
    <>
        {original ? (
        <NavLink
          to={
            original.vendor_form_status === NEEDS_FOLLOWUP
              ? `/supplier/pendingForms/${original.form_id}/submitAnswers/${original.vendor_id}/followup`
              : original.vendor_form_status === DRAFT
              ? `/supplier/pendingForms/${original.form_id}/submitAnswers/${original.vendor_id}/draft`
              : original.vendor_form_status === IN_PROGRESS ? `/supplier/pendingForms/${original.form_id}/submitAnswers/${original.vendor_id}/inprogress` : `/supplier/pendingForms/submitAnswers/${original.form_id}` 
          }
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              color: "black",
            }}
          >
            {props.getValue()}{" "}
          </span>{" "}
        </NavLink>
      ) : null}
      </>
  );
};
  const renderRoleLelvePendingForms = () => {
    switch (userDetails.role) {
      case SUPPLIER:
        return (
          <>
            {supplierPendingForms.length ? (
              <>
                {/* <DataTable header={headerList} data={supplierPendingForms}/> */}
                <GroupTable rowData={supplierPendingForms} columns={headerList} />
                {/* <AgGridTable
                  columnDefs={headerList}
                  rowData={supplierPendingForms}
                  handleAgGridRef={setGridRefData}
                /> */}
              </>
            ) : (
              <>
                <Alert variant="success">No forms to view</Alert>
              </>
            )}
          </>
        );
        break;
      case ADMIN:
        return (
          <>
            {supplierPendingForms.length ? (
              <>
                <GroupTable rowData={supplierPendingForms} columns={headerList}/>
                {/* <AgGridTable
                  rowData={supplierPendingForms}
                  columnDefs={headerList}
                  defaultColDef={{
                    resizable: true,
                    minWidth: 100,
                  }}
                  // pagination={pagination}
                  // isPaginationRequired={true}
                  // actionType={SUPPLIER_SENT_FORMS}
                  // fetchParamsList={fetchParamsList}
                  handleAgGridRef={setGridRefData}
                /> */}
              </>
            ) : (
              <>
                <Alert variant="success">No forms to view</Alert>
              </>
            )}
          </>
        );
        break;
      case CLIENT:
        return (
          <>
            {supplierPendingForms.length ? (
              <>
              {/* <AgGridGroupingTable  columnDefs={headerList}
                  rowData={supplierPendingForms}
                  groupBy={'form_name'}
                  /> */}
                <GroupTable rowData={supplierPendingForms} columns={headerList} group_name={["sent_to"]}/>
                {/* <DataTable header={headerList} data={supplierPendingForms}/> */}
                {/* <AgGridTable
                  columnDefs={headerList}
                  rowData={supplierPendingForms}
                  handleAgGridRef={setGridRefData}
                /> */}
              </>
            ) : (
              <>
                <Alert variant="success">No forms to view</Alert>
              </>
            )}
          </>
        );
        break;

      default:
        return <DataTable header={headerList} data={supplierPendingForms} />;
        break;
    }
  };
  const hanldeSearchByText = async(searchBy)=>{
    if (userDetails.role === SUPPLIER) {
      if (location.pathname === "/supplier/completedForms") {
        await dispatch(
          supplierCompletedFormsAction({
            supplier_id: userDetails.supplier_id,
            page: 1,
            per_page: PER_PAGE,searchBy
          })
        );
      } else {
        await dispatch(
          supplierPendingFormsAction({
            supplier_id: userDetails.supplier_id,
            page: 1,
            per_page: PER_PAGE,searchBy
          })
        );
      }
    }
    if (userDetails.role === ADMIN) {
      await dispatch(
        clientPendingFormsAction({
          client_id: clientId,
          page: 1,
          per_page: PER_PAGE,searchBy
        })
      );
    }
    if (userDetails.role === CLIENT) {
      
      await dispatch(
        clientPendingFormsAction({
          client_id: userDetails.client_id,
          page: 1,
          per_page: PER_PAGE,searchBy
        })
      );
    }
  }
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="row">
           <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"></div>
           <div className="col-xs-6 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-right">
             <SearchBy hanldeSearchByText={hanldeSearchByText} />
           </div>
          </div>
          <div className="spacer"></div>
          {renderRoleLelvePendingForms()}
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </PageLoader>
    </div>
  );
};

export default PendingForms;
