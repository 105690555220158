import React, { useState, useEffect } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import {
  CHOOSE_MORE_THAN_ONE,
  CHOOSE_ONE_ONLY,
  CONDITIONAL_QUESTIONS,
  LONG_ANSWER,
  MULTIPLE_CHOICE_WITH,
  QUESTION_NUMBER,
  SHORT_ANSWER,
  SUBMIT_ANSWERS,
  OPTIONS,
  STATEMENT,
  ADMIN_FORM_ANSWERS,
  YES_OR_NO,
  FORM_BUILDER,
  DATE_TYPE,
  NEEDS_FOLLOWUP_ANSWERS,
  DRAFT_ANSWERS,
  EDIT_PUBLISHED_FORM,
  IN_PROGRESS,IN_PROGRESS_ANSWERS, FILE_TYPE, NOT_OPENED, DRAFT
} from "../../common/constants";
import { saveAs } from "file-saver";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from "react-select";
import { checkIsQuestionAdded } from "../../redux/actions/questionLibraryAction";
import CustomFileUpload from "../../common/CustomFileUpload";
import { useSelector } from "react-redux";

const Questions = (props) => {
  const {
    data,
    actions,
    checkBoxSelection,
    redirectFrom,
    isAddFromLibrary,
    sectionName,
    answersList,
  } = props;
  const {
    formStatus,
  } = useSelector((state) => state.form);
  const [questionsList, setQuestionsList] = useState([]);
  const [isOtherSelected, setIsOtherSelected] = useState({});
  const [profileFile, setProfileFile] = useState({})
  useEffect(async () => {
    setQuestionsList(data);
    if (
      [ADMIN_FORM_ANSWERS, NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
        redirectFrom
      )
    ) {
      let params = {};
      data.filter((obj) => {
        if (obj.question_type === YES_OR_NO) {
          if (obj.answer && ["Yes", "No"].indexOf(obj.answer[0]) === -1) {
            params[`${obj.id}`] = {
              isSelected: true,
              value: obj.answer[0],
            };
          }
        }
      });
      setIsOtherSelected(params);
    }
  }, [data]);

  const handleChange = (event, labelData) => {
    const { name, value } = event.currentTarget;
    let params = { ...isOtherSelected };
    if (value === "Other") {
      // setIsOtherSelected(!isOtherSelected)
      params[`${labelData.question_id}`] = {
        isSelected: true,
        value: null,
      };
      setIsOtherSelected(params);
      props.sendAnswers({
        sectionName,
        ...labelData,
        value,
        question_option_id: labelData.id,
        attachment:null
      });
    } else {
      // setIsOtherSelected(false)
      delete params[`${labelData.question_id}`];
      setIsOtherSelected(params);
      handleAnswers(event, labelData);
    }
  };

  const handleAnswers = (event, labelData, answerData) => {
    const { name, value, checked } = event.currentTarget;
    if (
      redirectFrom === SUBMIT_ANSWERS ||
      [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
    ) {
      if (labelData.type === MULTIPLE_CHOICE_WITH) {
        props.sendAnswers({
          sectionName,
          ...labelData,
          value: [
            {
              question_option_id: labelData.id,
              value,
            },
          ],
          question_option_id: labelData.id,attachment:null
        });
      } else {
        props.sendAnswers({
          sectionName,
          ...labelData,
          value,
          question_option_id: labelData.id,attachment:null
        });
      }
    }
  };

  const handleMoreThanOne = (inputData, labelData) => {
    if (Object.keys(inputData).length > 0) {
      console.log({
        sectionName,
        ...labelData,
        value: inputData.value,
        question_id: labelData.id,
        question_option_id: inputData.id,
      });
      if (
        redirectFrom === SUBMIT_ANSWERS ||
        [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
      ) {
        props.sendAnswers({
          sectionName,
          ...labelData,
          value: inputData.value,
          question_id: labelData.id,
          question_option_id: inputData.id,attachment:null
        });
      }
    }
  };
  const uploadFiles = (files,labelData)=>{
    setProfileFile(files)
    props.sendAnswers({
          sectionName,
          ...labelData,
          value:null,
          question_option_id: labelData.id,
          callIngFrom: "UploadFile",
          attachment:files
    });
  }

  const renderQuestionOptions = (
    type,
    labelData,
    option_type,
    questionData
  ) => {
    switch (type) {
      case OPTIONS:
        switch (option_type) {
          case CHOOSE_ONE_ONLY:
            return (
              <>
                <input
                  type="radio"
                  name={
                    labelData ? `${sectionName}_${labelData.question_id}` : null
                  }
                  value={
                    [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                      redirectFrom
                    )
                      ? answersList[`${sectionName}`][`${questionData.id}`][
                          "value"
                        ] === labelData.option_number
                        ? answersList[`${sectionName}`][`${questionData.id}`][
                            "value"
                          ]
                        : labelData.option_number
                      : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                      ? questionData.answer
                        ? questionData.answer[0]
                        : null
                      : labelData
                      ? labelData.option_number
                      : null
                  }
                  onChange={(inputData) => {
                    handleAnswers(inputData, {
                      ...labelData,
                      type: CHOOSE_ONE_ONLY,
                    });
                  }}
                  checked={
                    [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                      redirectFrom
                    )
                      ? answersList[`${sectionName}`][`${questionData.id}`][
                          "value"
                        ] === labelData.option_number
                      : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                      ? (questionData.answer
                          ? questionData.answer[0]
                          : null) === labelData.option_number
                      : null
                  }
                  disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
                />
              </>
            );
            break;
          case CHOOSE_MORE_THAN_ONE:
            return (
              <>
                <Select
                  // value={questionData.questions_options.filter(obj=> obj.value === "Books")}
                  value={
                    [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                      redirectFrom
                    )
                      ? questionData.questions_options.filter(
                          (obj) =>
                            obj.value ===
                            answersList[`${sectionName}`][`${questionData.id}`][
                              "value"
                            ]
                        )
                      : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                      ? questionData.answer
                        ? questionData.questions_options.filter(
                            (obj) => obj.value === questionData.answer[0]
                          )
                        : null
                      : labelData
                      ? labelData.option_number
                      : null
                  }
                  // value={ [ADMIN_FORM_ANSWERS].includes(redirectFrom) ? (
                  // questionData.answer ? questionData.questions_options.filter(obj=> obj.value === questionData.answer[0]) : null )  :
                  // ( labelData ? labelData.option_number : null )}
                  options={questionData.questions_options}
                  placeholder="Choose single response"
                  isClearable={true}
                  onChange={(params) => {
                    handleMoreThanOne(params, {
                      ...labelData,
                      type: CHOOSE_MORE_THAN_ONE,
                    });
                  }}
                  isDisabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
                  // isMulti={true}
                />
                {/* <input
                  type="checkbox"
                  name={labelData ? labelData.question_id : null}
                  value={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? ( (questionData.answer ? questionData.answer[0] : null ) ) : ( labelData ? labelData.option_number : null )}
                  onChange={(inputData)=>{
                    handleAnswers(inputData,{ ...labelData ,type: CHOOSE_MORE_THAN_ONE})
                  }}
                  disabled={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom)}
                  checked={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? (questionData.answer ?  questionData.answer.includes(labelData.option_number) : null ) : null }
                /> */}
              </>
            );
            break;
          case CONDITIONAL_QUESTIONS:
            return (
              <input
                type="checkbox"
                name={labelData ? labelData.question_id : null}
                value={labelData ? labelData.option_number : null}
                onChange={(inputData) => {
                  handleAnswers(inputData, {
                    ...labelData,
                    type: CONDITIONAL_QUESTIONS,
                  });
                }}
                disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
                checked={
                  [
                    ADMIN_FORM_ANSWERS,
                    NEEDS_FOLLOWUP_ANSWERS,
                    DRAFT_ANSWERS,IN_PROGRESS_ANSWERS
                  ].includes(redirectFrom)
                    ? questionData.answer
                      ? questionData.answer[0]
                      : null
                    : labelData
                    ? labelData.option_number
                    : null
                }
              />
            );
            break;
          case MULTIPLE_CHOICE_WITH:
            const answer = questionData.answer ? questionData.answer : null;
            // console.log("------------",answersList[`${sectionName}`],answersList[`${sectionName}`][`${questionData.id}`],)
            // console.log("*******",answersList[`${sectionName}`][`${questionData.id}`]['value'])
            return (
              <>
                <input
                  type="checkbox"
                  name={labelData ? labelData.question_id : null}
                  // value={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? ( (questionData.answer ? questionData.answer[0] : null ) ) : ( labelData ? labelData.option_number : null )}
                  value={
                    [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                      redirectFrom
                    )
                      ? answersList[`${sectionName}`][`${questionData.id}`] &&
                        answersList[`${sectionName}`][`${questionData.id}`][
                          "value"
                        ].filter((obj) => obj.value === labelData.option_number)
                          .length > 0
                        ? answersList[`${sectionName}`][`${questionData.id}`][
                            "value"
                          ].filter(
                            (obj) => obj.value === labelData.option_number
                          )[0].value
                        : labelData.option_number
                      : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                      ? answer
                        ? answer[0]
                        : null
                      : labelData
                      ? labelData.option_number
                      : null
                  }
                  onChange={(inputData) => {
                    handleAnswers(inputData, {
                      ...labelData,
                      type: MULTIPLE_CHOICE_WITH,
                      answerData:
                        [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                          redirectFrom
                        ) &&
                        Object.keys(answersList).length > 0 &&
                        answersList[`${sectionName}`].hasOwnProperty(
                          `${questionData.id}`
                        ) &&
                        answersList[`${sectionName}`][`${questionData.id}`][
                          "value"
                        ].filter((obj) => obj.value === labelData.option_number)
                          .length > 0
                          ? answersList[`${sectionName}`][`${questionData.id}`][
                              "value"
                            ]
                          : [],
                    });
                  }}
                  disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
                  checked={
                    [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(
                      redirectFrom
                    )
                      ? answersList[`${sectionName}`][`${questionData.id}`] &&
                        answersList[`${sectionName}`][`${questionData.id}`][
                          "value"
                        ].filter((obj) => obj.value === labelData.option_number)
                          .length > 0
                        ? answersList[`${sectionName}`][`${questionData.id}`][
                            "value"
                          ]
                            .map((obj) => obj.value)
                            .includes(labelData.option_number)
                        : false
                      : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                      ? answer
                        ? answer.includes(labelData.option_number)
                        : null
                      : null
                  }
                />
              </>
            );
            break;

          default:
            break;
        }
      case SHORT_ANSWER:
        return (
          <input
            type="text"
            name={labelData ? labelData.id : null}
            value={
              [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                ? answersList[`${sectionName}`][`${questionData.id}`]["value"]
                : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                ? questionData.answer
                  ? questionData.answer[0]
                  : null
                : null
            }
            className="form-control"
            onChange={(inputData) => {
              handleAnswers(inputData, {
                question_id: labelData.id,
                ...labelData,
                type,
              });
            }}
            disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
          />
        );
      case LONG_ANSWER:
        return (
          <textarea
            rows="2"
            class="form-control"
            id="exampleFormControlTextarea1"
            maxLength="200"
            name={labelData ? labelData.id : null}
            value={
              [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                ? answersList[`${sectionName}`][`${questionData.id}`]["value"]
                : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                ? questionData.answer
                  ? questionData.answer[0]
                  : null
                : null
            }
            onChange={(inputData) => {
              handleAnswers(inputData, {
                question_id: labelData.id,
                ...labelData,
                type,
              });
            }}
            disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
          />
        );
      case QUESTION_NUMBER:
        return (
          <input
            type="number"
            name={labelData ? labelData.id : null}
            className="form-control"
            onChange={(inputData) => {
              handleAnswers(inputData, {
                question_id: labelData.id,
                ...labelData,
                type,
              });
            }}
            value={
              [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                ? answersList[`${sectionName}`][`${questionData.id}`]["value"]
                : [
                    ADMIN_FORM_ANSWERS,
                    NEEDS_FOLLOWUP_ANSWERS,
                    DRAFT_ANSWERS,IN_PROGRESS_ANSWERS
                  ].includes(redirectFrom)
                ? questionData.answer
                  ? questionData.answer[0]
                  : null
                : null
            }
            disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
          />
        );
      case YES_OR_NO:
        let answer = null;
        if ([NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)) {
          answer = answersList[`${sectionName}`][`${questionData.id}`]["value"];
          if (["Yes", "No"].includes(answer)) {
          } else {
            answer = "Other";
          }
        } else {
          if (questionData.answer) {
            answer = questionData.answer[0];
            if (["Yes", "No"].includes(answer)) {
            } else {
              answer = "Other";
            }
          }
        }
        return (
          <>
            <input
              type="radio"
              name={
                labelData ? `${sectionName}_${labelData.question_id}` : null
              }
              // value={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? ( (questionData.answer ? questionData.answer[0] : null ) ) : ( labelData ? labelData.option_number : null )}
              value={
                [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                  ? answersList[`${sectionName}`][`${questionData.id}`][
                      "value"
                    ] === labelData.option_number
                    ? answer
                    : labelData.option_number
                  : [ADMIN_FORM_ANSWERS, NEEDS_FOLLOWUP_ANSWERS].includes(
                      redirectFrom
                    )
                  ? answer
                  : labelData
                  ? labelData.option_number
                  : null
              }
              onChange={(inputData) => {
                // handleAnswers(inputData,{...labelData,type: YES_OR_NO})
                handleChange(inputData, { ...labelData, type: YES_OR_NO });
              }}
              // checked={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? ( (questionData.answer ? questionData.answer[0] : null ) === labelData.option_number ) : null }
              checked={
                [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                  ? answer === labelData.option_number
                  : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                  ? answer === labelData.option_number
                  : null
              }
              disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
            />
          </>
        );
      case DATE_TYPE:
        return (
          <>
            <Form.Control
              type="date"
              placeholder="select Date"
              aria-describedby="inputGroupPrepend"
              min={new Date().toISOString().split("T")[0]}
              className={`form-control `}
              style={{ backgroundColor: "#e6e6e6" }}
              onChange={(inputData) => {
                handleAnswers(inputData, {
                  question_id: labelData.id,
                  ...labelData,
                  type,
                });
              }}
              value={
                [NEEDS_FOLLOWUP_ANSWERS, DRAFT_ANSWERS,IN_PROGRESS_ANSWERS].includes(redirectFrom)
                  ? answersList[`${sectionName}`][`${questionData.id}`]["value"]
                  : [ADMIN_FORM_ANSWERS].includes(redirectFrom)
                  ? questionData.answer
                    ? questionData.answer[0]
                    : null
                  : null
              }
              disabled={[ADMIN_FORM_ANSWERS].includes(redirectFrom)}
            />
            {/* <input type="number" name={labelData ? labelData.id : null} className="form-control" onChange={(inputData)=>{
              handleAnswers(inputData,{question_id: labelData.id,...labelData,type})
            }} value = { [ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom) ? (questionData.answer ? questionData.answer[0] : null ) : null }  disabled={[ADMIN_FORM_ANSWERS,NEEDS_FOLLOWUP_ANSWERS].includes(redirectFrom)}/> */}
          </>
        );
      // case FILE_TYPE:
      //   return(
      //     <>
      //       {questionData.answer ? (
      //         <>
      //           <i
      //         className={"fa fa-download"}
      //         aria-hidden="true"
      //         title={"Download"}
      //         onClick={() => {
      //           downloadAction({attachment: questionData.answer});
      //         }}
      //         style={{ fontSize: "20px", paddingTop: "7px", cursor: "pointer" }}
      //       ></i>
      //         </>
      //       ) : (
      //      <>
      //        <CustomFileUpload uploadFile={(files)=>{
      //         uploadFiles(files,{
      //           question_id: labelData.id,
      //           ...labelData,
      //           type,
      //         })
      //       }} isPreviewRequired={true} fileData={profileFile} />
      //      </>
      //       )}
      //     </>
      //   )
      default:
      // return (
      //   <input
      //     type="radio"
      //     name={labelData.question_id}
      //     value={labelData.option_number}
      //   />
      // );
    }
  };
  const downloadAction = async (value) => {
    saveAs(value.attachment);
  };

  const renderQuestionActions = (name, icon, clickAction, id, section,attachment) => {
    switch (name) {
      case "Add":
        return (
          <Button
            variant="btn app-default-button btn-sm btn-primary"
            type="button"
            onClick={() => {
              clickAction(id);
            }}
          >
            Add
          </Button>
        );

      case FILE_TYPE:
        return (
          <i
              className={"fa fa-download"}
              aria-hidden="true"
              title={"Download"}
              onClick={() => {
                downloadAction({attachment});
              }}
              style={{ fontSize: "20px", paddingTop: "7px", cursor: "pointer" }}
            ></i>
        );

      default:
        return (
          <>
            <i
              className={icon}
              aria-hidden="true"
              title={name}
              onClick={() => {
                clickAction({ id, section,attachment });
              }}
              style={{ fontSize: "20px", paddingTop: "7px", cursor: "pointer" }}
            ></i>
          </>
        );
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      questionsList,
      result.source.index,
      result.destination.index
    );
    const position = result.destination.index - result.source.index;
    // const sectionName = result.draggableId.split(`-${result.source.index}`)
    const reqParams = {
      question_id: parseInt(result.draggableId),
      number_of_positions_change: position,
      section: props.sectionName,
    };
    setQuestionsList(items);
    props.questionReorderAction(reqParams);

    // dispatch(reorderSectionsAction(reqParams))
  };
  const grid = 20;
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    // width: 200
  });
  const renderQuestionView = () => {
    return (
      <>
        <Droppable
          droppableId={props.dragType}
          type={`droppableSubItem`}
          isCombineEnabled={true}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={
                redirectFrom === FORM_BUILDER
                  ? getListStyle(snapshot.isDraggingOver)
                  : null
              }
            >
              {questionsList.map((obj, index) => (
                <>
                  <Draggable
                    key={`${obj.id}`}
                    draggableId={`${obj.id}`}
                    index={index}
                    isDragDisabled={redirectFrom !== FORM_BUILDER}
                  >
                    {(provided, snapshot) => (
                      // <div
                      //   // ref={provided.innerRef}
                      //   // {...provided.draggableProps}
                      //   // {...provided.dragHandleProps}
                      // >
                      <div
                        className={`question-list ${
                          obj.isChecked ? "question-selected" : null
                        } ${obj.isValid} ${
                          obj.question_type === STATEMENT
                            ? "statement-question"
                            : null
                        }`}
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="row">
                          {obj.isChecked}
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                            {redirectFrom === "FormBuilder" &&
                            obj.is_library ? (
                              <i
                                className="fa fa-star"
                                style={{ color: "#318c2d" }}
                              ></i>
                            ) : null}
                            {isAddFromLibrary ? (
                              <>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    label={`    ${index + 1} .       ${
                                      obj.question
                                    }`}
                                    checked={obj.isChecked}
                                    onChange={() => {
                                      checkBoxSelection(obj);
                                    }}
                                  />
                                </Form.Group>
                              </>
                            ) : (
                              <>
                                <div>
                                  {index + 1}.
                                  <b> &nbsp;&nbsp;{obj.question} </b>{" "}
                                </div>
                                <div className="spacer"></div>
                              </>
                            )}

                            {/* <div className="spacer"></div> */}
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <div className="row">
                              <div className="col-6"></div>
                              {actions.map((actionObj, index) => (
                                <>
                                {( actionObj?.name === "Download" && obj?.attachment === null ) ? null: (
                                  <div className="col-1">
                                  { ( [EDIT_PUBLISHED_FORM].indexOf(props.redirectFrom) !==-1 ? false :  props.is_published ) ? null : (
                                    <>
                                      {renderQuestionActions(
                                        actionObj.name,
                                        actionObj.icon,
                                        actionObj.onClick,
                                        obj.id,
                                        props.sectionName,
                                        obj?.attachment
                                      )}
                                    </>
                                  )}
                                </div>
                                )}
                                </>
                              ))}
                              {actions.length ===0  ? (
                                <>
                                { [NOT_OPENED,IN_PROGRESS].indexOf(formStatus) !==-1  && <CustomFileUpload uploadFile={(files)=>{
                                    uploadFiles(files,{
                                      question_id: obj.id,
                                      ...obj,
                                      type: obj.question_type,
                                    })
                                  }} isPreviewRequired={true} fileData={profileFile} />}
                                {obj?.attachment && <i
                                    className={"fa fa-download"}
                                    aria-hidden="true"
                                    title={"Download"}
                                    onClick={() => {
                                      downloadAction({attachment: obj.attachment});
                                    }}
                                    style={{ fontSize: "20px", paddingTop: "7px", cursor: "pointer" }}
                                  ></i>}
                                </>
                              ): null }
                            </div>
                            <div className="spacer"></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                            <div className="row">
                              {obj.options_type === CHOOSE_MORE_THAN_ONE ? (
                                <>{renderQuestionOptions({}, {}, {}, obj)}</>
                              ) : null}
                              {obj.questions_options.length > 0 &&
                              obj.options_type !== CHOOSE_MORE_THAN_ONE ? (
                                <>
                                  {" "}
                                  {obj.questions_options.map(
                                    (questionOption, questionOptionIndex) => (
                                      <>
                                        {obj.question_type === YES_OR_NO ? (
                                          <>
                                            <div
                                              className={
                                                Object.keys(isOtherSelected)
                                                  .length > 0 &&
                                                isOtherSelected[
                                                  `${questionOption.question_id}`
                                                ] &&
                                                questionOption.option_number ===
                                                  "Other"
                                                  ? "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                                                  : "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
                                              }
                                              key={questionOptionIndex}
                                            >
                                              {/* <div className="row">
                                    <div className="col-6"> */}
                                              {renderQuestionOptions(
                                                obj.question_type,
                                                questionOption,
                                                obj.options_type,
                                                obj
                                              )}{" "}
                                              &nbsp;&nbsp;
                                              {questionOption.option_number}
                                              {/* </div>
                                </div> */}
                                              {Object.keys(isOtherSelected)
                                                .length > 0 &&
                                              isOtherSelected[
                                                `${questionOption.question_id}`
                                              ] &&
                                              questionOption.option_number ===
                                                "Other" ? (
                                                <div
                                                  className="spacer supporting-info"
                                                  style={{
                                                    marginLeft:
                                                      "27px !important",
                                                  }}
                                                >
                                                  <textarea
                                                    rows="3"
                                                    name="otherInfo"
                                                    className="form-control"
                                                    placeholder="Enter Supporting Info"
                                                    onChange={(inputData) => {
                                                      handleAnswers(inputData, {
                                                        ...questionOption,
                                                        type: YES_OR_NO,
                                                      });
                                                    }}
                                                    disabled={[
                                                      ADMIN_FORM_ANSWERS,
                                                    ].includes(redirectFrom)}
                                                    value={
                                                      [
                                                        ADMIN_FORM_ANSWERS,
                                                        NEEDS_FOLLOWUP_ANSWERS,
                                                        DRAFT_ANSWERS,IN_PROGRESS_ANSWERS
                                                      ].includes(redirectFrom)
                                                        ? isOtherSelected[`${questionOption.question_id}`].value
                                                        : null
                                                    }
                                                  />
                                                </div>
                                              ) : null}
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                              key={questionOptionIndex}
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {renderQuestionOptions(
                                                obj.question_type,
                                                questionOption,
                                                obj.options_type,
                                                obj
                                              )}{" "}
                                              &nbsp;
                                              {questionOption.option_value}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )
                                  )}{" "}
                                </>
                              ) : (
                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                                  {renderQuestionOptions(
                                    obj.question_type,
                                    obj,
                                    obj.options_type,
                                    obj
                                  )}{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    )}
                  </Draggable>
                </>
              ))}
            </div>
          )}
        </Droppable>
        {data.length === 0 ? (
          <Alert variant="success">No questions to view</Alert>
        ) : null}
      </>
    );
  };
  return (
    <>
      {data.length === 0 && false ? (
        <>
          <Alert variant="success">No questions to view</Alert>
        </>
      ) : (
        <>
          {props.dragType ? (
            <>{renderQuestionView()}</>
          ) : (
            <>
              <DragDropContext
              // onDragEnd={onDragEnd}
              >
                {renderQuestionView()}
              </DragDropContext>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Questions;
