import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import CreateForm from "../FormBuilder/createForm";
import { ADMIN_FORM_ANSWERS, EDIT_FORM, SUBMIT_ANSWERS } from "../../common/constants";
import Footer from "../Footer";
export default function EditForm(props) {
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="spacer"></div>
          <CreateForm redirectFrom={EDIT_FORM} />
          <div className="flex-grow-1"></div>
                    <Footer /> 
        </div>
      </PageLoader>
    </div>
  );
}
