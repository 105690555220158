import React, { useState, useEffect, useRef, useCallback } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getSuppliersList,
  getVerticlesData,
  deleteSupplier,
  getAdminSuppliersListAction,
  GET_ADMIN_SUPPLIERS_LIST,
  resetPaginationAction,
  updateAdminSuppliersList,
  getAdminSuppliersList,
  getClientSuppliersList,
  getAllSuppliersList,
} from "../../redux/actions/suppliersAction";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "./../../common/Loader";
import { Alert, Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link, NavLink, useParams } from "react-router-dom";
import { ADMIN, CLIENT, SUPPLIER, PER_PAGE } from "../../common/constants";
import InviteSupplier from "./InviteSupplier";
import AgGridTable from "../../components/Tables/agGridTable";
import Footer from "../../pages/Footer";
import { SearchBy } from "../../common/SearchBy";


export default function Suppliers(props) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const gridRef = useRef();
  const clientId = params.client_id;
  const { userDetails } = useSelector((state) => state.user);
  const { supplierList, loader, verticleList, adminSupplierList, pagination } =
    useSelector((state) => state.supplier);
  const [isOpenBsicData, setIsOpenBasicData] = useState(false);
  const [verticleData, setVerticleData] = useState([]);
  const [sideMenu, setSideMenu] = useState(true);
  const [isInviteSupplier, setIsInviteSupplier] = useState(false);
  const [agGridRef, setAgGridRef] = useState(null);
  const [allData, setAllData] = useState([]);
  const [rowNode, setRowNode] = useState(null);
  

  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  useEffect(() => {
    dispatch(resetPaginationAction());
    async function fetchVerticleData() {
      if (userDetails && userDetails.role === CLIENT) {
        // await dispatch(getSuppliersList({ id: userDetails.id }));
        if( pathname === "/client/suppliers/searchSuppliers/list" ){
          await dispatch(getAllSuppliersList({ id: userDetails.id }));
       }else{
          await dispatch(getSuppliersList({ id: userDetails.id }));
       }
        await dispatch(getVerticlesData());
      } else if (userDetails && userDetails.role === ADMIN) {
        await dispatch(getClientSuppliersList({ client_id: clientId }));
      } else {
        await dispatch(
          getAdminSuppliersListAction({
            // page:1,
            // per_page:PER_PAGE
          })
        );
        await dispatch(getVerticlesData());
      }
    }

    fetchVerticleData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = verticleList.map((obj) => {
      return { label: obj, value: obj };
    });
    setVerticleData(data);
  }, [verticleList]);

  const filterData = async (selectedOption) => {
    await dispatch(getSuppliersList({ ...selectedOption, id: userDetails.id }));
  };
  const editAction = (data) => {
    setIsOpenBasicData(true);
  };
  const deleteAction = async (data, removeIndex) => {
    await dispatch(deleteSupplier({ id: data.id }));
    agGridRef.api.updateRowData({ remove: [data] });
    // await dispatch(getSuppliersList({ id: userDetails.id }));
    // allData.splice(removeIndex, 1);
    // gridRef.current.api.refreshInfiniteCache();
  };
  let navigationUrl = "";
  if (userDetails && userDetails.role === CLIENT) {
    navigationUrl =
      pathname === "/client/suppliers/searchSuppliers/list"
        ? "/client/suppliers/searchSuppliers/list"
        : "suppliers";
  } else {
    navigationUrl = "/admin/suppliers";
  }
  const headerList = [
    {
      name: "Vendor Name",
      key: "name",
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          handleAction: editAction,
          // url: location
          url: navigationUrl,
        },
      ],
    },
    { name: "Primary Vertical", key: "primary_vertical" },
    { name: "Secondary Vertical", key: "secondary_vertical" },
    { name: "Revenue", key: "revenue" },
    { name: "Email", key: "email" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "Delete",
          icon: "fa fa-trash",
          handleAction: deleteAction,
        },
        // {
        //   actionKey: "edit",
        //   icon: "fa fa-pencil",
        //   handleAction: editAction,
        // },
      ],
    },
  ];
  const AidRenderComponent = (props) => {
    return (
      <>
        {props.data ? (
          <NavLink to={`${navigationUrl}/${props.data.id}`}>
            <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
            >
              {props.value}
            </span>{" "}
          </NavLink>
        ) : null}
      </>
    );
  };
  const deleteRow = (props) => {
    return (
      <i
        class="fa fa-trash text-center ag-grid-row-icons"
        aria-hidden="true"
        title="Delete"
        onClick={() => {
          // const rowNodeObj = props.agGridRef.current.api.getRowNode(String(props.data.id));
          // const removeIndex = props.agGridRef.current.props.rowData.map(obj=> obj.id).indexOf(props.data.id)
          const removeIndex = props.node.rowIndex;
          props.delete(props.data, removeIndex);
        }}
      ></i>
    );
  };

  const columnDefs = [
    {
      headerName: "Vendor Name",
      field: "name",
      // filter: true,
      sortable: true,
      // floatingFilter: true,
      cellRendererFramework: AidRenderComponent,
    },
    {
      headerName: "Primary Vertical",
      field: "primary_vertical",
      // floatingFilter: true,
      sortable: true,
      // filter: true,
      sortable: true,
    },
    {
      headerName: "Secondary Vertical",
      field: "secondary_vertical",
      // floatingFilter: true,
      // filter: true,
      sortable: true,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      // floatingFilter: true,
      // filter: true,
      sortable: true,
    },
    {
      headerName: "Email",
      field: "email",
      // floatingFilter: true,
      sortable: true,
      // filter: true,
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "deleterow",
      cellRendererParams: {
        delete: deleteAction,
        agGridRef,
      },
      hide: userDetails.role === ADMIN
    },
  ];
  const closeModal = () => {
    setIsInviteSupplier(false);
  };
  const handleInviteSupplier = () => {
    setIsInviteSupplier(true);
  };
  const setGridRefData = (params) => {
    setAgGridRef(params);
  };
  const fetchParamsList = () => {
    return {};
  };
  const upateParentRowData = async (data) => {
    dispatch(
      updateAdminSuppliersList({
        data: data.data.data,
        total_items: data.data.pagination.total_count,
        page: data.page,
        total_pages: data.data.pagination.total_pages,
      })
    );
  };
  const datasourceData = {
    getRows: async (params) => {
      const { startRow, endRow, filterModel, sortModel } = params;
      const filterKeys = Object.keys(filterModel);
      let reqParams = await fetchParamsList();
      reqParams.page = endRow / pagination.per_page;
      reqParams.per_page = pagination.per_page;
      if (sortModel.length) {
        const { colId, sort } = sortModel[0];
        reqParams.sort_by = colId;
        reqParams.order_by = sort.toUpperCase();
      }
      //Filtering
      filterKeys.forEach((filter) => {
        // url += `${filter}=${filterModel[filter].filter}&`
        reqParams[`${filter}`] = filterModel[filter].filter;
      });
      let rows = [];
      let totalItems = 0;
      const data = await getAdminSuppliersList(reqParams);
      if (data && data.data.success) {
        totalItems = data.data.pagination.total_count;
        rows = data.data.data;
        const newData = allData.concat(rows);
        setAllData([...newData]);
        params.successCallback(rows, totalItems);
      } else {
        params.failCallback();
      }
    },
  };
  const onGridReady = async (params) => {
    params.api.sizeColumnsToFit();
    setAllData([]);

    await params.api.setDatasource(datasourceData);
  };
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);


  const hanldeSearchByText = async(searchBy)=>{
    if (userDetails && userDetails.role === CLIENT) {
      await dispatch(getSuppliersList({ id: userDetails.id,searchBy }));
    } else if (userDetails && userDetails.role === ADMIN) {
      await dispatch(getClientSuppliersList({ client_id: clientId,searchBy }));
    } else {
      await dispatch(
        getAdminSuppliersListAction({
          searchBy
        })
      );
      await dispatch(getVerticlesData());
    }
  }
  
  return (
    <div className="page-wrapper">
      <PageLoader loader={loader}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          {pathname === "/client/suppliers" ? (
            <>
              {isInviteSupplier ? (
                <InviteSupplier
                  isOpen={isInviteSupplier}
                  handleClose={closeModal}
                />
              ) : null}
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"></div>
                <div className="col-xs-6 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-right">
                  <SearchBy hanldeSearchByText={hanldeSearchByText} />
                </div>
                <div className="col-xs-6 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-right">
                  <Button
                    className="btn app-default-button"
                    onClick={handleInviteSupplier}
                  >
                    {" "}
                    Invite Supplier{" "}
                  </Button>
                </div>
              </div>
              <div className="spacer"></div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"></div>
                <div className="col-xs-6 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-right">
                  <SearchBy hanldeSearchByText={hanldeSearchByText} />
                </div>
              </div>
              <div className="spacer"></div>
            </>
          )}

          {/* <div className="row">
            <div className="col-12">
              <Filters
                suplierOptions={suplierOptions}
                primaryVerticalOptions={verticleData}
                secondaryVerticalOptions={verticleData}
                revenueOptions={revenueOptions}
                filterData={filterData}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12">
              {userDetails && userDetails.role === CLIENT || userDetails.role === ADMIN ? (
                <>
                  {supplierList.length > 0 ? (
                    <>
                      {/* <SuppliersList
                      headerList={headerList}
                      supplierData={supplierList}
                    /> */}
                      <AgGridTable
                        columnDefs={columnDefs}
                        rowData={supplierList}
                        frameworkComponents={{
                          deleterow: deleteRow,
                        }}
                        handleAgGridRef={setGridRefData}
                      />
                    </>
                  ) : (
                    <Alert variant="success">No suppliers to view</Alert>
                  )}
                </>
              ) : (
                <>
                  {adminSupplierList.length > 0 ? (
                    <>
                      {isInviteSupplier ? (
                        <InviteSupplier
                          isOpen={isInviteSupplier}
                          handleClose={closeModal}
                        />
                      ) : null}
                      <div className="row">
                        <div className="col-10"></div>
                        <div className="col-2 text-right">
                          <Button
                            className="btn app-default-button"
                            onClick={handleInviteSupplier}
                          >
                            {" "}
                            Invite Supplier{" "}
                          </Button>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div>
                        {/* <SuppliersList
                      headerList={headerList}
                      supplierData={adminSupplierList}
                    /> */}
                        <AgGridTable
                          columnDefs={columnDefs}
                          rowData={adminSupplierList}
                          frameworkComponents={{
                            deleterow: deleteRow,
                          }}
                          handleAgGridRef={setGridRefData}
                        />
                        {/* <AgGridTable 
                        rowData={adminSupplierList} 
                        columnDefs={columnDefs} 
                        defaultColDef={{
                          resizable: true,minWidth: 100}}
                        frameworkComponents={{
                          deleterow: deleteRow
                        }}
                        pagination={pagination}
                        // datasource={datasource}
                        isPaginationRequired={true}
                        actionType={GET_ADMIN_SUPPLIERS_LIST}
                        fetchParamsList={fetchParamsList}
                        handleAgGridRef={setGridRefData}
                        upateParentRowData={upateParentRowData}
                       /> */}
                        {/* <div className="ag-theme-alpine" style={{height:500}}>
                                  <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs} 
                                    defaultColDef={{resizable: true,minWidth: 100}}
                                    frameworkComponents={{
                                      deleterow: deleteRow
                                    }}
                                    enableCellChangeFlash={true}
                                    // rowData={rowData}
                                    rowModelType="infinite"
                                    onGridReady={onGridReady}
                                    cacheBlockSize= {PER_PAGE}
                                    maxConcurrentDatasourceRequests={1}
                                    infiniteInitialRowCount={1}
                                    defaultColDef={{
                                      resizable: true,minWidth: 100}}
                                    getRowId={getRowId}
                                    {...props}
                                  />
                                </div> */}
                      </div>
                    </>
                  ) : (
                    <Alert variant="success">No suppliers to view</Alert>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </PageLoader>
    </div>
  );
}
