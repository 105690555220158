import React, { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  adminClientsListActionUpdate,
  clientDeleteAction,
  getAdminClients,
  getAdminClientsList,
  getSuppliersList,
  getVerticlesData,
  GET_ADMIN_CLIENTS_LIST,
  resetPaginationAction,
  setClientViewAction,
} from "../../redux/actions/suppliersAction";
import { useDispatch, useSelector } from "react-redux";
import Filters from "./filters";
import ClientList from "./clientsList";
import { suplierOptions, revenueOptions } from "./constants";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "./../../common/Loader";
import { Alert, Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  CLIENT,
  PER_PAGE,
  DEFAULT_PER_PAGE,
  ADMIN,
} from "../../common/constants";
import AgGridTable from "../../components/Tables/agGridTable";

import Footer from "../../pages/Footer";
import { SearchBy } from "../../common/SearchBy";

export default function Clients(props) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  //  const gridRef = useRef();
  const { userDetails } = useSelector((state) => state.user);
  const { supplierList, loader, verticleList, adminClientsList, pagination } =
    useSelector((state) => state.supplier);
  const [isOpenBsicData, setIsOpenBasicData] = useState(false);
  const [verticleData, setVerticleData] = useState([]);
  const [sideMenu, setSideMenu] = useState(true);
  const [gridRef, setGridRef] = useState(null);
  const [rowNode, setRowNode] = useState(null);
  const [allData, setAllData] = useState([]);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  useEffect(() => {
    dispatch(resetPaginationAction());
    async function fetchVerticleData() {
      if (userDetails && userDetails.role === CLIENT) {
        await dispatch(getSuppliersList({ id: userDetails.id }));
        await dispatch(getVerticlesData());
      } else {
        await dispatch(
          getAdminClients({
            // page:1,
            // per_page:PER_PAGE
          })
        );
        // await dispatch(getVerticlesData());
      }
    }
    fetchVerticleData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = verticleList.map((obj) => {
      return { label: obj, value: obj };
    });
    setVerticleData(data);
  }, [verticleList]);

  const filterData = async (selectedOption) => {
    await dispatch(getSuppliersList({ ...selectedOption, id: userDetails.id }));
  };
  const editAction = (data) => {
    setIsOpenBasicData(true);
  };
  const deleteAction = async (data) => {};
  const headerList = [
    {
      name: "Client Name",
      key: "name",
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          handleAction: editAction,
          // url: location
          url: "/admin/clients",
        },
      ],
    },
    { name: "Business Vertical", key: "business_vertical" },
    { name: "Country", key: "country" },
    { name: "State", key: "state" },
    { name: "Email", key: "email" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "Delete",
          icon: "fa fa-trash",
          handleAction: deleteAction,
        },
      ],
    },
  ];
  const AidRenderComponent = (props) => {
    return (
      <>
        {props.data ? (
          <>
            {userDetails.role === ADMIN ? (
              <>
                {/* <NavLink to={`/admin/clients/${props.data.id}`}> */}
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      color: "black",
                      cursor:"pointer"
                    }}
                    onClick={()=>{
                      navigateTo(`/admin/clients/${props.data.id}`,{isClientViewOpen:true,client_id:props.data.id,name: props.data.name})
                    }}
                  >
                    {props.value}
                  </span>
                {/* </NavLink> */}
              </>
            ) : (
              <>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  {props.value}
                </span>
              </>
            )}
          </>
        ) : null}
      </>
    );
  };

  const navigateTo = (url,data)=>{
     dispatch(setClientViewAction({
       client_id: data.client_id,
       name: data.name
     }));
    props.history.push(url,data)
  }
  const deleteRow = (props) => {
    return (
      <>
      <div className="row">
        <div className="col-6" style={{paddingTop:"10px"}}>
          {/* <NavLink to={`/admin/clients/${props.data.id}`}> */}
            <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
              onClick={()=>{
                navigateTo(`/admin/clients/${props.data.id}`,{isClientViewOpen:true, client_id: props.data.id})
              }}
            >
              <i
                class="fa fa-pencil text-center ag-grid-row-icons"
                aria-hidden="true"
                onClick={() => {
                }}
              ></i>
            </span>
          {/* </NavLink> */}
        </div>
        <div className="col-6">
        <span
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                color: "black",
              }}
            >
            <i
              class="fa fa-trash text-center ag-grid-row-icons"
              aria-hidden="true"
              onClick={() => {
                const removeIndex = props.node.rowIndex;
                props.delete(props.data, removeIndex);
              }}
            ></i>
          </span>
        </div>
      </div>
      </>
    );
  };

  const handleRowDeletion = async (data, removeIndex) => {
    await dispatch(clientDeleteAction({ id: data.id }));
    gridRef.api.updateRowData({ remove: [data] });
    // allData.splice(removeIndex,1);
    // gridRef.current.api.refreshInfiniteCache();
  };

  const columnDefs = [
    {
      headerName: "Client Name",
      field: "name",
      // filter: true,
      sortable: true,
      // floatingFilter: true,
      cellRendererFramework: AidRenderComponent,
    },
    {
      headerName: "Business Vertical",
      field: "business_vertical",
      // filter: true,
      sortable: true,
      // floatingFilter: true,
    },
    {
      headerName: "Country",
      field: "country",
      // filter: true,
      // floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "State",
      field: "state",
      // filter: true,
      // floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      // filter: true,
      // floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "deleterow",
      maxWidth: 100,
      cellRendererParams: {
        delete: handleRowDeletion,
        gridRef,
      },
    },
  ];

  const setGridRefData = (params) => {
    setGridRef(params);
  };
  const fetchParamsList = () => {
    return {};
  };

  const upateParentRowData = async (data) => {
    dispatch(
      adminClientsListActionUpdate({
        data: data.data.data,
        total_items: data.data.pagination.total_count,
        page: data.page,
        total_pages: data.data.pagination.total_pages,
      })
    );
  };
  const datasourceData = {
    getRows: async (params) => {
      const { startRow, endRow, filterModel, sortModel } = params;
      const filterKeys = Object.keys(filterModel);
      let reqParams = await fetchParamsList();
      reqParams.page = endRow / pagination.per_page;
      reqParams.per_page = pagination.per_page;
      if (sortModel.length) {
        const { colId, sort } = sortModel[0];
        reqParams.sort_by = colId;
        reqParams.order_by = sort.toUpperCase();
      }
      //Filtering
      filterKeys.forEach((filter) => {
        // url += `${filter}=${filterModel[filter].filter}&`
        reqParams[`${filter}`] = filterModel[filter].filter;
      });
      let rows = [];
      let totalItems = 0;
      const data = await getAdminClientsList(reqParams);
      if (data && data.data.success) {
        totalItems = data.data.pagination.total_count;
        rows = data.data.data;
        const newData = allData.concat(rows);
        setAllData([...newData]);
        params.successCallback(rows, totalItems);
      } else {
        params.failCallback();
      }
    },
  };
  const onGridReady = async (params) => {
    params.api.sizeColumnsToFit();
    setAllData([]);

    await params.api.setDatasource(datasourceData);
  };
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);

  const hanldeSearchByText = async(searchBy)=>{
   if (userDetails && userDetails.role === CLIENT) {
        await dispatch(getSuppliersList({ id: userDetails.id,searchBy }));
      } else {
        await dispatch(
          getAdminClients({
            searchBy
            // page:1,
            // per_page:PER_PAGE
          })
        );
        // await dispatch(getVerticlesData());
      }
}
  return (
    <div className="page-wrapper">
      <PageLoader loader={loader}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="row">
                      <div className="col-10"></div>
                      <div className="col-2"><SearchBy hanldeSearchByText={hanldeSearchByText} /></div>
          </div>
          <div className="spacer"></div>
          {pathname === "/suppliers" ? (
            <>
              <div className="row">
                <div className="col-10"></div>
                <div className="col-2">
                  <Button
                    variant="btn app-default-button btn-sm btn-primary"
                    type="button"
                  >
                    <Link
                      to="/client/suppliers/searchSuppliers/list"
                      className="app-default-button btn-sm btn-primary"
                    >
                      {/* Search Supplier &nbsp;&nbsp;<i className="fa fa-arrow-right"></i> */}
                    </Link>
                  </Button>
                </div>
              </div>{" "}
            </>
          ) : null}

          {/* <div className="row">
            <div className="col-12">
              <Filters
                suplierOptions={suplierOptions}
                primaryVerticalOptions={verticleData}
                secondaryVerticalOptions={[{
                  label:"India",
                  value: "India"
                },{
                  label:"USA",
                  value: "USA"
                }]}
                revenueOptions={[{
                  label:"Newyork",
                  value: "Newyork"
                }]}
                filterData={filterData}
                role = {userDetails.role}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12">
              {adminClientsList.length > 0 ? (
                <>
                  {/* <ClientList
                  headerList={headerList}
                  // supplierData={supplierList}
                  data ={adminClientsList}
                /> */}
                  <AgGridTable
                    columnDefs={columnDefs}
                    rowData={adminClientsList}
                    frameworkComponents={{
                      deleterow: deleteRow,
                    }}
                    perPage={DEFAULT_PER_PAGE}
                    handleAgGridRef={setGridRef}
                  />
                  {/* <AgGridTable 
                  rowData={adminClientsList} 
                  columnDefs={columnDefs} 
                  defaultColDef={{resizable: true,minWidth: 100}}
                  frameworkComponents={{
                    deleterow: deleteRow
                  }}
                  pagination={pagination}
                  isPaginationRequired={true}
                  actionType={GET_ADMIN_CLIENTS_LIST}
                  fetchParamsList={fetchParamsList}
                  handleAgGridRef={setGridRefData}
                  upateParentRowData={upateParentRowData}
              /> */}
                  {/* <div className="ag-theme-alpine" style={{height:500}}>
                                  <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs} 
                                    defaultColDef={{resizable: true,minWidth: 100}}
                                    frameworkComponents={{
                                      deleterow: deleteRow
                                    }}
                                    enableCellChangeFlash={true}
                                    // rowData={rowData}
                                    rowModelType="infinite"
                                    onGridReady={onGridReady}
                                    cacheBlockSize= {PER_PAGE}
                                    maxConcurrentDatasourceRequests={1}
                                    infiniteInitialRowCount={1}
                                    defaultColDef={{
                                      resizable: true,minWidth: 100}}
                                    getRowId={getRowId}
                                    {...props}
                                  />
                                </div> */}
                </>
              ) : (
                <Alert variant="success">No clients to view</Alert>
              )}
            </div>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </PageLoader>
    </div>
  );
}
